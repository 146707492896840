import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { useConnect } from "wagmi";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import { Button } from "../../atoms";
import { ConnectSwitch } from "../../molecules/ConnectWalletButtons/ConnectSwitch";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

export const ConnectWalletDialog = () => {
  const { signIn, token } = useAuthenticationContext();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [{ data: walletData, error: walletError }, connect] = useConnect();

  useEffect(() => {
    if (token) closeModal();
  }, [walletData.connector, walletError, token]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function signInWithLoading() {
    setLoading(true);

    signIn().then(() => setLoading(false));
  }

  return (
    <>
    <div className="hidden lg:block">
      <Button text="Connect Wallet" onClick={openModal} />
    </div>
    <div className="lg:hidden sm:block">
      <button className="bg-blue-500 py-1 px-2 items-center justify-center rounded-md" onClick={openModal}>
        <AccountBalanceWalletIcon className="h-7 w-7 text-white"/>
      </button>
    </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {
            if (walletData) {
              closeModal();
            }
          }}
        >
          <div className="min-h-screen px-4 text-center bg-slate-500 bg-opacity-40 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300 transform"
              enterFrom="opacity-0 scale-95 translate-y-8"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-200 transform"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 scale-95 translate-y-8"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl md:max-w-2xl rounded-2xl">
                <div className="flex items-center justify-between mb-6">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-semibold leading-6 text-gray-900"
                  >
                    Connect your wallet
                  </Dialog.Title>
                  <button
                    onClick={closeModal}
                    className="p-2 rounded-full hover:bg-gray-100 active:bg-gray-200"
                  >
                    <XIcon className="w-5 h-5 text-gray-600" />
                  </button>
                </div>
                {walletData.connected && (
                  <div className="flex flex-col mb-8 space-y-8">
                    <Button
                      text={`Sign message with ${walletData.connector?.name}`}
                      loading={loading}
                      onClick={signInWithLoading}
                    ></Button>
                    <div className="flex items-center justify-center w-full">
                      <div className="w-full h-px bg-gray-300" />
                      <span className="flex-none px-4 text-gray-500">
                        {" "}
                        OR connect a different wallet
                      </span>
                      <div className="w-full h-px bg-gray-300" />
                    </div>
                  </div>
                )}
                <div className="grid grid-cols-2 md:grid-cols-3">
                  {walletData.connectors.map(
                    (connector) =>
                      connector.ready && (
                        <ConnectSwitch
                          key={connector.id}
                          connector={connector}
                          onClick={() => {
                            // if you want to sign message if the wallet is already connected
                            // removed for UX, but in case it's needed again at some point
                            // if (walletData.connected) {
                            //   signIn();
                            // } else {
                            //  connect(connector);
                            // }
                            connect(connector);
                          }}
                        />
                      )
                  )}
                </div>

                {walletError && (
                  <div>
                    <p className="p-4 font-medium text-red-500 bg-red-100 rounded-md">
                      {walletError.message}
                    </p>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
