import { useEffect, useState } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import mixpanel from "../../mixpanel/mixpanel";
import useLobbyRouter from "../useLobbyRouter";

// Since this is called within a page because of routing,
// it gets recalled in the useEffect
export function useMixpanelDocumentReadEvent() {
  const { communitySlug, documentSlug, locale } = useLobbyRouter();
  const { user, token } = useAuthenticationContext();

  const [trackedCommunity, setTrackedCommunity] = useState("");
  const [trackedDocument, setTrackedDocument] = useState("");

  useEffect(() => {
    // timeout so that we can fetch user in the
    // auth context if it isnt already there
    setTimeout(() => {
      // prevent tracking garbage
      if (
        !communitySlug ||
        communitySlug === "[community]" ||
        documentSlug === "[documentSlug]" ||
        // prevents double tracking reads
        (trackedCommunity === communitySlug &&
          trackedDocument === documentSlug) ||
        // in the auth context it takes a second to fetch
        // user when there is a token
        (!user && token)
      )
        return;

      mixpanel.track("Document read", {
        communitySlug,
        documentSlug,
        locale,
        // user is set by checking wallet, so see if they are logged in
        // then send the request with the userAddress (not everyone will be logged
        // in, but most of the time we will have the user)
        userAddress: token ? user?.address : null,
      });

      setTrackedCommunity(communitySlug);
      setTrackedDocument(documentSlug);
    }, 2000);
  }, [mixpanel, communitySlug, documentSlug, locale, user, token]);

  return;
}
