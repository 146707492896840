import { Autocomplete, Box, styled, TextField } from "@mui/material";
import classNames from "classnames";
import Image from "next/image";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import tokenImage from "../../../../public/assets/tokenImage/tokenImage.png";
import { Token } from "../../../generated/graphql";
import { patternMatch } from "../../../lib/util/util";
import { Button } from "../Button/Button";

interface CommunityTokenProps {
  token: Token;
  onSave: (e: React.FormEvent<HTMLFormElement>, id: string) => void;
  buttonText: string;
}

export const networkOptions = ["mainnet"];
export const typeOptions = ["erc-20", "erc-721", "erc-1155"];
const iconStyle = "bg-transparent fill-white rounded-full p-0";

const StyledTextField = styled(TextField)({
  "&& .MuiOutlinedInput-root": {
    paddingRight: "10%",
  },
});

export const CommunityToken = ({
  token,
  onSave,
  buttonText,
}: CommunityTokenProps) => {
  const [tokenType, setTokenType] = useState(token.type);
  const [tokenIds, setTokenIds] = useState(token.tokenId);

  function handleTokenChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (event.target.value) {
      if (!patternMatch(event.target.value, "^[0-9-,]+$")) {
        enqueueSnackbar(
          "Token Id field can only contain digits, hyphens or commas.",
          {
            variant: "error",
          }
        );
      } else {
        setTokenIds(event.target.value);
      }
    } else {
      setTokenIds("");
    }
  }
  const { enqueueSnackbar } = useSnackbar();
  return (
    <div className="min-w-full p-3 my-1 border-2 rounded-xl">
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          onSave(e, token.id);
        }}
        autoComplete="off"
      >
        <div className="flex flex-row">
          <div className="w-10 h-10 mr-5 rounded-full">
            <div className="p-0 bg-transparent rounded-full fill-white">
              {token.imageUrl === "" ? (
                <Image
                  src={tokenImage}
                  alt="Default Token"
                  width={500}
                  height={500}
                  className="p-1"
                />
              ) : (
                <img src={token.imageUrl} alt="Token" className="p-1" />
              )}
            </div>
          </div>
          <div>
            <input
              id="tokenName"
              className="font-bold placeholder-black"
              type="string"
              defaultValue={token.name}
              autoComplete="off"
            />
            <div className="text-sm text-slate-400">{token.address}</div>
          </div>
        </div>
        <div
          className={classNames(
            tokenType === "erc-1155" ? "grid-cols-5" : "grid-cols-4",
            "grid mt-4 w-full"
          )}
        >
          <Box className="flex flex-col mr-1">
            <Autocomplete
              className="rounded-sm "
              id="network"
              typeof="string"
              disablePortal
              size="small"
              options={networkOptions}
              defaultValue={token.network}
              renderInput={(params) => (
                <StyledTextField {...params} label="Network" />
              )}
            />
          </Box>
          <Box className="mr-1">
            <Autocomplete
              className="rounded-sm "
              id="type"
              typeof="string"
              disablePortal
              size="small"
              options={typeOptions}
              defaultValue={token.type}
              renderInput={(params) => (
                <StyledTextField {...params} label="Type" />
              )}
              onChange={(e, val: string | null) => {
                setTokenType(val ?? "");
              }}
            />
          </Box>
          {tokenType === "erc-1155" ? (
            <Box className="mr-1">
              <StyledTextField
                id="tokenId"
                label="Token Id"
                variant="outlined"
                size="small"
                value={tokenIds}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleTokenChange(e)}
              />
            </Box>
          ) : (
            <></>
          )}
          <Box className="mr-1">
            <Autocomplete
              freeSolo
              id="image"
              options={[]}
              size="small"
              defaultValue={token.imageUrl}
              renderInput={(params) => (
                <StyledTextField {...params} label="Image URL" />
              )}
            />
          </Box>
          <Box className="flex flex-col">
            <Autocomplete
              freeSolo
              id="address"
              options={[]}
              defaultValue={token.address}
              size="small"
              renderInput={(params) => (
                <StyledTextField {...params} label="Address" />
              )}
            />
          </Box>
        </div>
        <div className="flex justify-end mt-2">
          <Button text={buttonText} />
        </div>
      </form>
    </div>
  );
};
