import { useEnsAvatar, useEnsLookup } from "wagmi";
import { trimEllip } from "../../../lib/util/util";
import { RevealAddress } from "../RevealAddress/RevealAddress";

interface ENSProps {
  address: string;
  onlyAvatar?: boolean;
}

export function ENSNameAndAvatar({ address, onlyAvatar }: ENSProps) {
  const [{ data: ensAvatarImg }] = useEnsAvatar({
    addressOrName: address,
  });

  if (onlyAvatar) {
    if (ensAvatarImg) {
      return (
        <div>
          <img className="w-4 h-4 mr-1 rounded-full" src={ensAvatarImg} />
        </div>
      );
    }
    return (
      <div>
        <img className="w-4 h-4 mr-1" src="/assets/avatar/default.svg" />
      </div>
    );
  } else {
    if (ensAvatarImg) {
      return (
        <div className="flex items-center">
          <img className="w-4 h-4 mr-1 rounded-full" src={ensAvatarImg} />
          <RevealAddress address={address} />
        </div>
      );
    }
    return (
      <div className="flex items-center">
        <img className="w-4 h-4 mr-1" src="/assets/avatar/default.svg" />
        <RevealAddress address={address} />
      </div>
    );
  }
}
