export const reservedDocumentNames = [
  "new",
  "moderator",
  "admin",
  "super-admin",
  "account",
  "profile",
  "search",
];

export const defaultLandingBackgroundImage = "https://images.unsplash.com/photo-1464802686167-b939a6910659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2300&q=80"
