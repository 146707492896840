import { FolderAddIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
interface FolderButton {
  onClick: () => void;
  hidden?: boolean;
}

export const FolderButton = ({
  onClick,
  hidden = false,
  children
}: PropsWithChildren<FolderButton>) => {
  return (
    <button
      className={classNames(
        hidden && "invisible",
        !hidden && "hover:bg-gray-300 active:bg-gray-400",
        "p-1 rounded"
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <div
        className={classNames(
          "w-4 h-4 text-gray-600 hover:cursor-pointer"
        )}
      >
        {children}
      </div>
    </button>
  );
};
