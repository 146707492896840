import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ClockIcon,
  EyeIcon,
  PencilAltIcon,
  SelectorIcon,
  TranslateIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import React, { Fragment } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import { ListItem } from "../../../interfaces";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";

export const modes: ListItem[] = [
  {
    name: "View Mode",
    slug: "",
    description: "Read the article, view translations.",
    icon: <EyeIcon />,
    shortcut: "V",
  },
  {
    name: "Edit Mode",
    slug: "edit",
    description: "Write or edit the page’s content.",
    icon: <PencilAltIcon />,
    shortcut: "E",
  },
  {
    name: "Translate Mode",
    slug: "translate",
    description: "Side-by-side two languages at once.",
    icon: <TranslateIcon />,
    shortcut: "T",
  },
  {
    name: "Revisions Mode",
    slug: "revisions",
    description: "Travel through time and view changes.",
    icon: <ClockIcon />,
    shortcut: "R",
  },
];

interface ModeSelectorProps {
  currentMode: ListItem;
  isCommunityIndexPage: boolean;
  isNewDocument: boolean;
  setMode: (value: ListItem) => void;
  isWriter: boolean;
}

export const ModeSelector = ({
  currentMode,
  isCommunityIndexPage,
  isNewDocument,
  setMode,
  isWriter,
}: ModeSelectorProps) => {
  const { token, user } = useAuthenticationContext();
  const { communitySlug } = useLobbyRouter();
  let shownModes: ListItem[] = modes;

  // On community index page, only show view option,
  // On new document only show edit option
  if (isCommunityIndexPage) {
    shownModes = modes.slice(0, 1);
    currentMode = modes[0];
  } else if (isNewDocument) {
    shownModes = modes.slice(1, 2);
    currentMode = modes[1];
  } else if (!token || !isWriter) {
    // Only show view and revisions when not logged in
    shownModes = [modes[0], modes[3]];
  } else if (token && user?.userId === communitySlug)
    shownModes = [modes[0], modes[1], modes[3]];

  return (
    <div className="w-full">
      <Listbox
        value={currentMode}
        onChange={(mode) => {
          !isCommunityIndexPage && setMode(mode);
        }}
      >
        <div className="relative z-10">
          <Listbox.Button className="relative py-2 pl-3 pr-10 text-left border border-gray-200 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 w-52 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="flex items-center truncate">
              <div className="w-3 h-3 text-gray-700">{currentMode.icon}</div>
              <span className="pl-2 text-xs font-medium text-gray-800">
                {currentMode.name}
              </span>
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition  ease-in duration-100"
            enterFrom="opacity-0 "
            enterTo="opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg w-80 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {shownModes.map((mode, id) => (
                <Listbox.Option
                  key={id}
                  className={({ active }) =>
                    `${active ? "text-blue-900 bg-blue-100" : "text-gray-900"}
                          cursor-default select-none relative py-2 pl-4`
                  }
                  value={mode}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-medium" : "font-normal"
                        } flex truncate items-center space-x-1`}
                      >
                        <div className="w-4 h-4">{mode.icon}</div>
                        <div className="flex flex-col pl-2">
                          <div className="flex items-center justify-start space-x-2">
                            <span className="text-sm">{mode.name}</span>
                            {mode?.shortcut && (
                              <div
                                className={classNames(
                                  "flex items-center justify-center w-4 h-4 text-xs font-semibold text-gray-100 rounded-sm",
                                  active ? "bg-blue-600" : "bg-gray-500"
                                )}
                              >
                                {mode?.shortcut}
                              </div>
                            )}
                          </div>
                          <div className="text-xs">{mode.description}</div>
                        </div>
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? "text-blue-600" : "text-blue-600"
                          }
                                absolute inset-y-0 right-0 flex items-center pr-3`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
