import {
  ChevronDownIcon,
  ChevronRightIcon,
  DocumentAddIcon,
  FolderAddIcon,
  PencilAltIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import Link from "next/link";
import { createContext, useCallback, useContext, useState } from "react";
import { DotToolTip } from "..";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import { usePreferencesContext } from "../../../contexts/PreferencesContext";
import { Document } from "../../../generated/graphql";
import { ReducedFolder } from "../../../interfaces";
import { useFolderTranslationName } from "../../../lib/hooks/useFolderTranslationName";
import useLastEnglishTranslationMins from "../../../lib/hooks/useLastEnglishTranslationMins";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { FolderButton } from "../FolderButton/FolderButton";

interface FileTreeProps {
  itemBeingHovered: ReducedFolder | Document | null;
  isWriter: boolean;
  rootFolder: ReducedFolder;
  setItemBeingDragged: (el: ReducedFolder | Document | null) => void;
  setItemBeingHovered: (el: ReducedFolder | Document | null) => void;
  createFolder: (
    name: string,
    language: string,
    parentFolderName?: string
  ) => void;
  updateFolderName: (name: string, newName: string, language: string) => void;
  deleteFolder: (folderName: string) => void;
}

interface FileTreeContextInterface {
  isWriter: boolean;
  itemBeingHovered: ReducedFolder | Document | null;
  setItemBeingDragged: (el: ReducedFolder | Document | null) => void;
  setItemBeingHovered: (el: ReducedFolder | Document | null) => void;
  createFolder: (
    name: string,
    language: string,
    parentFolderName?: string
  ) => void;
  updateFolderName: (name: string, newName: string, language: string) => void;
  deleteFolder: (folderName: string) => void;
}

const FileTreeContext = createContext<FileTreeContextInterface>({
  itemBeingHovered: null,
  isWriter: false,
  setItemBeingDragged: (el: ReducedFolder | Document | null) => {},
  setItemBeingHovered: (el: ReducedFolder | Document | null) => {},
  createFolder: (
    name: string,
    language: string,
    parentFolderName?: string
  ) => {},
  updateFolderName: (name: string, newName: string, language: string) => {},
  deleteFolder: (folderName: string) => {},
});

const useFileTreeContext = () => {
  return useContext<FileTreeContextInterface>(FileTreeContext);
};

const TreeFolderComponent = ({
  folder,
  showChildren,
  onClick,
}: {
  folder: ReducedFolder;
  showChildren: boolean;
  onClick: () => void;
}) => {
  const {
    isWriter,
    itemBeingHovered,
    setItemBeingDragged,
    setItemBeingHovered,
    deleteFolder,
  } = useFileTreeContext();
  const { token } = useAuthenticationContext();
  const { setCreateNewFolderId } = usePreferencesContext();
  const { updateFolderName } = useFileTreeContext();
  const { locale } = useLobbyRouter();
  const [showFileTreeFolderButton, setShowFileTreeFolderButton] =
    useState<boolean>(false);
  const [editingFolderName, setEditingFolderName] = useState<boolean>(false);
  const [editingFolderNameVal, setEditingFolderNameVal] = useState<string>("");

  const { folderName } = useFolderTranslationName({ folder });

  return (
    <div
      draggable={!!token}
      onMouseOver={() => {
        if (isWriter) {
          setShowFileTreeFolderButton(true);
        }
      }}
      onMouseOut={() => {
        setShowFileTreeFolderButton(false);
      }}
      onDrag={() => {
        setItemBeingDragged(folder);
      }}
      onDragOver={() => {
        setItemBeingHovered(folder);
      }}
      onClick={onClick}
      className={classNames(
        "flex flex-row items-center py-1.5 text-gray-700 pl-4 pr-2 space-x-2 text-sm font-medium hover:cursor-pointer hover:bg-gray-200",
        (itemBeingHovered?.name === folder.name ||
          (itemBeingHovered as Document)?.folder?.name === folder.name ||
          (itemBeingHovered as ReducedFolder)?.subFolders?.filter(
            (fol: ReducedFolder) => fol?.name === folder.name
          ).length > 0) &&
          "bg-blue-200"
      )}
    >
      <div className="w-4 h-4">
        {showChildren ? <ChevronDownIcon /> : <ChevronRightIcon />}
      </div>
      <div>
        {editingFolderName ? (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              updateFolderName(folder.name, editingFolderNameVal, locale);
              setEditingFolderName(false);
            }}
          >
            <input
              autoFocus
              onChange={(e) => setEditingFolderNameVal(e.target.value)}
              placeholder={`${folderName}`}
              onBlur={() => setEditingFolderName(false)}
              className="w-full text-sm font-medium bg-inherit focus:outline-none"
            />
          </form>
        ) : (
          <h1>{folderName}</h1>
        )}
      </div>
      <div className="flex flex-row items-center justify-end flex-grow space-x-2">
        <FolderButton
          onClick={() => setEditingFolderName(!editingFolderName)}
          hidden={!showFileTreeFolderButton || editingFolderName}
        >
          <PencilAltIcon />
        </FolderButton>
        <FolderButton
          onClick={() => {
            setCreateNewFolderId(folder.id);
          }}
          hidden={!showFileTreeFolderButton}
        >
          <FolderAddIcon />
        </FolderButton>
        <FolderButton
          hidden={!showFileTreeFolderButton}
          onClick={() => {
            deleteFolder(folder.name);
          }}
        >
          <TrashIcon />
        </FolderButton>
      </div>
    </div>
  );
};

export const TreeDocumentComponent = ({
  document,
  href,
  isInContext,
  itemBeingHovered,
  setItemBeingDragged,
  setItemBeingHovered,
}: {
  document: Document;
  href: string;
  isInContext: boolean;
  itemBeingHovered?: ReducedFolder | Document | null;
  setItemBeingDragged?: (el: ReducedFolder | Document | null) => void;
  setItemBeingHovered?: (el: ReducedFolder | Document | null) => void;
}) => {
  // Need to provide these functions if this is not called inside the FileTree component
  // Inside the FileTree, a context is provided, but this component is also used in DocumentSidebar
  // so these props have to be passed down from there
  if (!isInContext && (!setItemBeingDragged || !setItemBeingHovered)) {
    throw Error(
      "Provide props if TreeDocumentPomponent is not in the FileTreeContext."
    );
  }
  const {
    itemBeingHovered: itemBeingHoveredContext,
    setItemBeingDragged: setItemBeingDraggedContext,
    setItemBeingHovered: setItemBeingHoveredContext,
  } = useFileTreeContext();
  if (isInContext) {
    itemBeingHovered = itemBeingHoveredContext;
    setItemBeingDragged = setItemBeingDraggedContext;
    setItemBeingHovered = setItemBeingHoveredContext;
  }

  const { documentSlug, locale } = useLobbyRouter();
  const { token } = useAuthenticationContext();

  const lastEnglishTranslationMins = useLastEnglishTranslationMins(document);

  return (
    <Link href={href}>
      <a>
        <div
          draggable={!!token}
          onDrag={() => {
            setItemBeingDragged && setItemBeingDragged(document);
          }}
          onDragOver={() => {
            setItemBeingHovered && setItemBeingHovered(document);
          }}
          className={classNames(
            document?.translations?.find(
              (object) => object.translation.language === locale
            )
              ? ""
              : "opacity-50",
            documentSlug === document.slug ? "bg-gray-200" : "",
            !document.folder && "",
            document?.folder?.name &&
              ((itemBeingHovered as Document)?.folder?.name ==
                document?.folder?.name ||
                (itemBeingHovered as ReducedFolder)?.name ==
                  document?.folder?.name) &&
              "bg-blue-200",
            "py-1.5 pl-4 pr-3 flex justify-between font-medium items-center text-sm hover:cursor-pointer text-gray-700 hover:bg-gray-200"
          )}
        >
          <p>{document.name}</p>
          {lastEnglishTranslationMins !== undefined &&
            lastEnglishTranslationMins < 120 && (
              <DotToolTip
                content={`English translation updated ${lastEnglishTranslationMins} mins ago`}
              />
            )}
        </div>
      </a>
    </Link>
  );
};

export const TreeInputComponent = ({
  parentFolderName,
  isInContext,
  createFolder,
}: {
  parentFolderName?: string;
  isInContext: boolean;
  createFolder?: (
    name: string,
    language: string,
    parentFolderName?: string
  ) => void;
}) => {
  const { locale } = useLobbyRouter();
  if (!isInContext && !createFolder) {
    throw Error(
      "Provide props if TreeInputComponent is not in the FileTreeContext."
    );
  }

  const { createFolder: createFolderContext } = useFileTreeContext();
  if (isInContext) {
    createFolder = createFolderContext;
  }

  const [name, setName] = useState("");
  const { setCreateNewFolderId } = usePreferencesContext();

  return (
    <div className="py-2 pl-6 pr-3">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setCreateNewFolderId(null);
          createFolder && createFolder(name, locale, parentFolderName);
        }}
      >
        <input
          autoFocus
          onChange={(e) => setName(e.target.value)}
          onBlur={() => setCreateNewFolderId(null)}
          placeholder="New folder name..."
          className="w-full mt-1 text-sm font-medium bg-gray-50 hover:cursor-pointer focus:outline-none"
        />
      </form>
    </div>
  );
};

export const FileTree = ({
  rootFolder,
  isWriter,
  itemBeingHovered,
  createFolder,
  updateFolderName,
  setItemBeingDragged,
  setItemBeingHovered,
  deleteFolder,
}: FileTreeProps) => {
  const { communitySlug } = useLobbyRouter();
  const { openFolders, setOpenFolders, createNewFolderId, currentMode } =
    usePreferencesContext();
  const folderKey = `${communitySlug},${rootFolder.name}`;

  const isNewFolderParent = useCallback((): boolean => {
    return createNewFolderId === rootFolder.id;
  }, [rootFolder.id, createNewFolderId]);

  return (
    <div>
      <FileTreeContext.Provider
        value={{
          isWriter,
          itemBeingHovered,
          createFolder,
          updateFolderName,
          setItemBeingDragged,
          setItemBeingHovered,
          deleteFolder,
        }}
      >
        <TreeFolderComponent
          folder={rootFolder}
          showChildren={openFolders[folderKey]}
          onClick={() => {
            setOpenFolders(folderKey, !openFolders[folderKey]);
          }}
        />
        {openFolders[folderKey] && (
          <>
            <FileTreeRecursive folders={rootFolder.subFolders} />
            <div
              className={classNames(
                "ml-5",
                rootFolder.documents.length > 0 && "border-l-2"
              )}
            >
              {isNewFolderParent() && (
                <TreeInputComponent
                  isInContext={true}
                  parentFolderName={rootFolder.name}
                />
              )}
              {rootFolder.documents.length > 0 ? (
                rootFolder.documents.map((document) => {
                  return (
                    <TreeDocumentComponent
                      key={document.slug}
                      isInContext={true}
                      document={document}
                      href={`/${communitySlug}/${document.slug}/${currentMode}`}
                    />
                  );
                })
              ) : (
                <p className="py-2 pl-3 pr-3 text-sm cursor-default text-slate-500 hover:bg-gray-200">
                  <Link href={`${communitySlug}/new`} passHref>
                    <div className="grid items-center justify-start grid-flow-col pl-3 space-x-2 hover:cursor-pointer">
                      <div>{"Create a new Document"}</div>
                      <div>
                        <DocumentAddIcon className="w-4 h-4 rounded-md" />
                      </div>
                    </div>
                  </Link>
                </p>
              )}
            </div>
          </>
        )}
      </FileTreeContext.Provider>
    </div>
  );
};

const FileTreeRecursive = ({ folders }: { folders: ReducedFolder[] }) => {
  const { communitySlug } = useLobbyRouter();
  const { openFolders, setOpenFolders, createNewFolderId, currentMode } =
    usePreferencesContext();

  const isNewFolderParent = useCallback(
    (folder: ReducedFolder): boolean => {
      return createNewFolderId === folder.id;
    },
    [createNewFolderId]
  );

  return (
    <div>
      {folders.map((folder) => {
        const folderKey = `${communitySlug},${folder.name}`;
        return (
          <div key={folder.name} className="ml-5 border-l-2 border-gray-200">
            <TreeFolderComponent
              folder={folder}
              showChildren={openFolders[folderKey]}
              onClick={() => {
                setOpenFolders(folderKey, !openFolders[folderKey]);
              }}
            />
            {openFolders[folderKey] && (
              <>
                <FileTreeRecursive folders={folder.subFolders} />
                <div className="ml-5 border-l-2">
                  {isNewFolderParent(folder) && (
                    <TreeInputComponent
                      isInContext={true}
                      parentFolderName={folder.name}
                    />
                  )}
                  {folder.documents.map((document) => {
                    return (
                      <TreeDocumentComponent
                        key={document.slug}
                        isInContext={true}
                        document={document}
                        href={`/${communitySlug}/${document.slug}/${currentMode}`}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
