import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Tooltip, TooltipProps as TippyProps } from "react-tippy";

interface ToolTipProps extends TippyProps {
  shortcut?: string[];
  content?: string;
  contentClassnames?: string;
}

export function ToolTip({
  children,
  shortcut = [],
  content,
  contentClassnames,
  ...props
}: PropsWithChildren<ToolTipProps>) {
  return (
    <Tooltip
      // theme="light"
      html={
        <div className="flex items-center">
          <div className={classNames(contentClassnames, "flex flex-col")}>
            {content}
          </div>
          {shortcut && shortcut.length > 0 && (
            <div className="flex flex-row ml-2 space-x-1">
              {shortcut.map((shortcut) => (
                <div
                  key={shortcut}
                  className="flex flex-row items-center p-1 bg-gray-500 rounded-sm h-4 min-w-[1rem] justify-center"
                >
                  <span className="text-sm">{shortcut}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
}
