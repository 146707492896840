import React, { useEffect } from "react";
import { ReducedFolder } from "../../interfaces";
import { findTranslation } from "../util/util";
import useLobbyRouter from "./useLobbyRouter";

export const useFolderTranslationName = ({
  folder,
}: {
  folder: ReducedFolder;
}) => {
  const { locale } = useLobbyRouter();
  const [folderName, setFolderName] = React.useState("");
  useEffect(() => {
    let translation = findTranslation(folder.translations, locale);

    /* legacy folders do not have a translation until
          they are updated so we use folder.name */
    setFolderName(translation?.name ?? folder.name);
  }, [folder.name, folder?.translations, locale]);

  return { folderName };
};
