import { usePreferencesContext } from "../../../contexts/PreferencesContext";
import { ReducedFolderWithRoles } from "../../../interfaces";
import { useFolderTranslationName } from "../../../lib/hooks/useFolderTranslationName";
import { RoleBadge } from "../RoleBadge/RoleBadge";

interface FolderTreeProps {
  communitySlug: string;
  currentDocumentSlug?: string;
  rootFolder: ReducedFolderWithRoles;
  currentFolder: ReducedFolderWithRoles | null;
  showRoles: boolean;
  setCurrentFolder: (folder: ReducedFolderWithRoles) => void;
}

interface FolderTreeRecursiveProps {
  communitySlug: string;
  currentDocumentSlug?: string;
  folders: ReducedFolderWithRoles[];
  currentFolder: ReducedFolderWithRoles | null;
  showRoles: boolean;
  setCurrentFolder: (folder: ReducedFolderWithRoles) => void;
}

interface TreeFolderComponentProps {
  folder: ReducedFolderWithRoles;
  showRoles: boolean;
  onClick: () => void;
}

const TreeFolderComponent = ({ folder, onClick }: TreeFolderComponentProps) => {
  const { folderName } = useFolderTranslationName({ folder });

  return (
    <div>
      <div
        onClick={onClick}
        className="flex flex-row flex-wrap items-center py-2 pl-3 pr-3 space-x-2 text-sm font-medium hover:cursor-pointer hover:bg-gray-300"
      >
        <h1>{folderName}</h1>
        {folder.readRoles.map((role) => {
          return <RoleBadge key={role.id} name={role.name} />;
        })}
      </div>
    </div>
  );
};

export const FolderTree = ({
  rootFolder,
  communitySlug,
  currentFolder,
  showRoles,
  setCurrentFolder,
}: FolderTreeProps) => {
  return (
    <div>
      <TreeFolderComponent
        folder={rootFolder}
        onClick={() => {
          setCurrentFolder(rootFolder);
        }}
        showRoles={showRoles}
      />
      <FolderTreeRecursive
        folders={rootFolder.subFolders}
        communitySlug={communitySlug}
        currentFolder={currentFolder}
        setCurrentFolder={setCurrentFolder}
        showRoles={showRoles}
      />
    </div>
  );
};

const FolderTreeRecursive = ({
  folders,
  communitySlug,
  currentFolder,
  showRoles,
  setCurrentFolder,
}: FolderTreeRecursiveProps) => {
  const { openFolders, setOpenFolders } = usePreferencesContext();
  return (
    <div>
      {folders.map((folder) => {
        const folderKey = `${communitySlug},${folder.name}`;
        return (
          <div
            key={folder.name}
            className="pl-3 ml-3 border-l-2 border-gray-300"
          >
            <TreeFolderComponent
              folder={folder}
              onClick={() => {
                setOpenFolders(folderKey, !openFolders[folderKey]);
                setCurrentFolder(folder);
              }}
              showRoles={showRoles}
            />
            <FolderTreeRecursive
              folders={folder.subFolders}
              communitySlug={communitySlug}
              currentFolder={currentFolder}
              setCurrentFolder={setCurrentFolder}
              showRoles={showRoles}
            />
          </div>
        );
      })}
    </div>
  );
};
