import { useLazyQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAccount, useConnect, useSigner, useSignMessage } from "wagmi";
import {
  MutationSignInArgs,
  QueryUserByAddressArgs,
  User,
} from "../generated/graphql";
import { setAuthToken } from "../lib/apollo/apollo-client";
import { useMixpanelAuthEvent } from "../lib/hooks/mixpanel";
import { UserSignInMutation } from "../lib/mutations/Users";
import { UserByAddressQuery, MeQuery } from "../lib/queries/Users";
import { createSignInMessage, isClient } from "../lib/util/util";

export interface AuthContext {
  user: User | null;
  token: string;
  signIn: () => Promise<void>;
  signOut: () => void;
}

export const AuthenticationContext = createContext<AuthContext>({
  user: null,
  token: "",
  signIn: async () => {},
  signOut: () => {},
});

export function useAuthenticationContext() {
  return useContext<AuthContext>(AuthenticationContext);
}

export const AuthProvider = ({ children }: PropsWithChildren<any>) => {
  const { enqueueSnackbar } = useSnackbar();
  const [{}, disconnect] = useAccount();
  const [{ data: walletData, error: walletError }] = useConnect();
  const [{ data: signerData }] = useSigner();
  // Error taken care in .then.catch in sign in
  const [{}, walletSignMessage] = useSignMessage();
  const { mixPanelSignInEvent } = useMixpanelAuthEvent();

  const [getUser, { error: userError, data: userData }] = useLazyQuery<
    { userByAddress: User },
    QueryUserByAddressArgs
  >(UserByAddressQuery, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    ssr: false,
    errorPolicy: "ignore",
  });
  const [me, { data: meData }] = useLazyQuery<{ me: User }>(MeQuery, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    ssr: false,
    errorPolicy: "ignore",
  });
  // Error taken care in .then.catch in sign in
  const [getToken, {}] = useMutation<{ signIn: string }, MutationSignInArgs>(
    UserSignInMutation,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [user, setUser] = useState<User | null>(null);
  const [authToken, setNewToken] = useState("");
  const [hasMounted, setHasMounted] = useState(false);

  // Sets token in local storage, auth provider, and in apollo
  const setToken = useCallback(
    async (token: string) => {
      if (isClient()) {
        localStorage.setItem("token", token);
      }
      setNewToken(token);
      await setAuthToken(token);
    },
    [setNewToken, setAuthToken, isClient]
  );

  // Signs message, then fetches token
  const signIn = async () => {
    if (user === null && signerData) {
      const address = await signerData.getAddress();
      await getUser({ variables: { address } });
    }

    if (
      user &&
      !authToken &&
      signerData &&
      walletData.connector !== undefined
      // don't necessarily need to wait for this state to change (i think)
      // &&      !walletSignLoading
    ) {
      const message = createSignInMessage({
        address: user.address,
        nonce: user.nonce,
        chainId: await signerData.getChainId(),
      });
      const signedMessage = await walletSignMessage({
        message,
      });
      if (signedMessage.error || !signedMessage.data) {
        enqueueSnackbar("Error signing message", {
          variant: "error",
        });
        return;
      }

      const token = await getToken({
        variables: {
          address: await signerData.getAddress(),
          signature: signedMessage.data,
          message,
        },
      });

      if (token.errors || !token.data?.signIn) {
        enqueueSnackbar("Error getting token", {
          variant: "error",
        });
        return;
      }

      await setToken(token.data.signIn);

      try {
        mixPanelSignInEvent(user.address);
      } catch (error) {
        console.error("Error sending mixpanel event", error);
      }
    }
  };

  // resets values
  const signOut = useCallback(async () => {
    disconnect();
    setUser(null);
    await setToken("");
    enqueueSnackbar("User signed out.", {
      variant: "info",
    });
  }, [disconnect, setUser, setToken, enqueueSnackbar]);

  // OnLoad, checks if token already exists
  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
      setToken(localStorage.getItem("token") ?? "");
    }
  }, [hasMounted]);

  useEffect(() => {
    if (walletError) {
      enqueueSnackbar("Could not connect to wallet.", {
        variant: "error",
      });
      return;
    }
  }, [walletError]);

  // If there is a token, refetch user
  useEffect(() => {
    if (authToken)
      me().then((res) => {
        if (res.data) setUser(res.data.me);
      });
  }, [authToken, me]);

  // Whenever the account changes, fetch new user
  useEffect(() => {
    if (!signerData) return;
    if (!user) {
      signerData.getAddress().then((address) => {
        getUser({ variables: { address } });
      });
      return;
    }

    signerData.getAddress().then((address) => {
      if (address !== user.address) {
        signOut();
        getUser({ variables: { address } });
      }
    });
  }, [signerData, user, getUser, signOut]);

  // When the user is received, set that to be the current one
  useEffect(() => {
    if (userError) {
      enqueueSnackbar("Error getting user.", {
        variant: "error",
      });
      return;
    }
    if (!userData) return;

    const newUser = userData.userByAddress;
    setUser(newUser);
  }, [userData, userError, setUser, enqueueSnackbar]);

  // Tries to sign in auth token, account, or user changes
  useEffect(() => {
    //Need this here for edge case, happens a lot in dev
    if (!user && signerData) {
      signerData.getAddress().then((address) => {
        getUser({ variables: { address } });
      });
      return;
    }

    if (!authToken && signerData && user && walletData) signIn();
  }, [authToken, signerData, user]);

  return (
    <AuthenticationContext.Provider
      value={{
        user: user,
        token: authToken,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
