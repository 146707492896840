import { differenceInMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { Document } from "../../generated/graphql";

export default function useLastEnglishTranslationMins(
  document: Document | undefined
) {
  const [lastUpdate, setLastUpdate] = useState<number | undefined>();

  useEffect(() => {
    if (!document || !document.translations) {
      setLastUpdate(undefined);
      return;
    }
    const englishTranslation = document.translations.find((translation) => {
      return translation.translation.language === "en-US";
    });
    const lastUpdate = new Date(
      englishTranslation?.latestRevision?.revision.createdAt
    );

    if (!lastUpdate) {
      setLastUpdate(undefined);
      return;
    }

    const now = new Date();
    setLastUpdate(differenceInMinutes(now, lastUpdate));
  }, [document]);

  return lastUpdate;
}
