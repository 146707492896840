/* eslint-disable @next/next/no-img-element */
import { useLazyQuery, useQuery } from "@apollo/client";
import { Listbox, Transition } from "@headlessui/react";
import { UserGroupIcon, UserIcon } from "@heroicons/react/outline";
import {
  CheckIcon,
  ChevronDownIcon,
  EmojiSadIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import { Community, QueryCommunityArgs } from "../../../generated/graphql";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import {
  CommunitiesQuery,
  CommunityQuery,
} from "../../../lib/queries/Communities";
import { updateContentWithPinataURL } from "../../../lib/util/util";
import { ClientOnly } from "../ClientOnly/ClientOnly";
import { DotToolTip } from "../ToolTip/DotToolTip";

const TopCommunitySlugs = [
  "global",
  "lobby",
  "shapeshift",
  "twali",
  "framework",
  "thedapplist",
  "hyboria",
];

interface CommunitySelectorProps {
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  textColor?: string;
}

const MyWorkspace: Community = {
  adminRoles: [],
  allowlist: { admins: [], moderators: [], readers: [], writers: [] },
  communityId: "",
  createdAt: new Date(),
  iconUrl: null,
  isAdmin: false,
  isModerator: false,
  isReader: false,
  isWriter: false,
  landingIcons: [],
  landingPrefs: {
    backgroundImageUrl: "",
    communityId: "",
    createdAt: new Date(),
    id: "",
    updatedAt: new Date(),
  },
  moderatorRoles: [],
  name: "My Workspace",
  prefs: {
    communityId: "",
    createdAt: new Date(),
    id: "",
    updatedAt: new Date(),
    isUserWorkspace: true,
  },
  readRoles: [],
  slug: "my-workspace",
  tags: [],
  updatedAt: new Date(),
  writeRoles: [],
};

const CustomListBoxOption = ({
  community,
}: {
  community: Community;
}): JSX.Element => {
  return (
    <Listbox.Option
      className={({ active }) =>
        classNames(
          active ? "text-gray-900  bg-gray-200 " : "text-gray-900  ", // dark:text-gray-200
          "cursor-pointer select-none relative py-2 pl-3 pr-9"
        )
      }
      value={community}
    >
      {({ selected, active }) => (
        <>
          <div className="flex items-center">
            <div className="flex-shrink-0 w-6 h-6 rounded-full">
              {community.iconUrl ? (
                // Uses img because url could come from external url
                // does not need alt because it's for display only
                <img className="rounded-full" alt="" src={community.iconUrl} />
              ) : (
                <>
                  {community.slug === "my-workspace" ? (
                    <UserIcon />
                  ) : (
                    <UserGroupIcon />
                  )}{" "}
                </>
              )}
            </div>

            <span
              className={classNames(
                selected ? "font-semibold" : "font-normal",
                "ml-3 flex flex-row truncate items-center"
              )}
            >
              {community.name}
              {community.slug === "my-workspace" && (
                <div className="pl-3">
                  <DotToolTip content={`NEW!`} />
                </div>
              )}
            </span>
          </div>

          {selected ? (
            <span
              className={classNames(
                "absolute inset-y-0 right-0 flex text-blue-600 items-center pr-4"
              )}
            >
              <CheckIcon aria-hidden="true" className="w-5 h-5" />
            </span>
          ) : (
            <></>
          )}
        </>
      )}
    </Listbox.Option>
  );
};

export const CommunitySelector = ({
  backgroundColor = "bg-transparent",
  hoverBackgroundColor = "bg-transparent",
  textColor = "text-white",
}: CommunitySelectorProps) => {
  const { token } = useAuthenticationContext();
  // router
  const { push, asPath, communitySlug } = useLobbyRouter();
  // get list of communities
  const { data } = useQuery<{ communities: Community[] }>(CommunitiesQuery);
  const [fetchCommunity, { data: communityData }] = useLazyQuery<
    { community: Community },
    QueryCommunityArgs
  >(CommunityQuery, {
    variables: { communitySlug },
    fetchPolicy: "cache-and-network",
  });

  // selected community
  const [currentCommunity, setCurrentCommunity] = useState<Community>();
  const [global, setGlobal] = useState<Community>();
  const [filter, setFilter] = useState<string>("");

  useEffect(() => {
    if (communitySlug !== "[community]" && communitySlug !== "my-workspace")
      fetchCommunity();
  }, [communitySlug]);

  useEffect(() => {
    // if not loaded, do nothing
    if (!data) return;

    if (communityData?.community?.name === "My Workspace") {
      setCurrentCommunity(MyWorkspace);
    }
    // if no current community or the communities don't match
    else if (!currentCommunity || communitySlug !== currentCommunity.slug)
      setCurrentCommunity(
        data.communities.find((community) => {
          if (asPath === "/") {
            return community.slug === "global";
          }
          return community.slug === communitySlug;
        })
      );

    // why is this here?
    setGlobal(
      data.communities.find((community) => {
        return community.slug === "global";
      })
    );
  }, [data, communitySlug, asPath, currentCommunity, communityData?.community]);

  const onSelectHandler = (value: Community) => {
    setCurrentCommunity(value);
    push(`/${value.slug}`);
    setFilter("");
  };

  const filterCommunities = (community: Community) => {
    if (!filter) return TopCommunitySlugs.indexOf(community.slug) === -1;

    return (
      community.slug.toLowerCase().includes(filter.toLowerCase()) ||
      community.name.toLowerCase().includes(filter.toLowerCase())
    );
  };

  return (
    <ClientOnly>
      <Listbox value={currentCommunity} onChange={onSelectHandler}>
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">
              Select a Community
            </Listbox.Label>
            <div className="relative">
              <Listbox.Button
                className={classNames(
                  "relative flex flex-row items-center w-full py-1 pl-3 pr-10 text-left rounded-xl shadow-sm hover:cursor-pointer",
                  backgroundColor,
                  hoverBackgroundColor
                )}
              >
                <span className="flex items-center">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full">
                    {currentCommunity?.iconUrl ? (
                      // Uses img because url could come from external url
                      // does not need alt because it is for display only
                      <img
                        className="rounded-full"
                        alt=""
                        src={updateContentWithPinataURL(
                          currentCommunity.iconUrl
                        )}
                      />
                    ) : (
                      <>
                        {currentCommunity?.slug === "my-workspace" ? (
                          <UserIcon className={classNames(textColor)} />
                        ) : (
                          <UserGroupIcon className={classNames(textColor)} />
                        )}
                      </>
                    )}
                  </div>
                  <span
                    className={`block ml-3 text-sm font-semibold ${textColor}`}
                  >
                    {currentCommunity?.name ? currentCommunity.name : "Global"}
                  </span>
                </span>
                <div className="pl-3">
                  <DotToolTip content={`NEW!`} />
                </div>
                <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <ChevronDownIcon
                    aria-hidden="true"
                    className={`w-5 h-5 ${textColor}`}
                  />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
              >
                {/* the height for this element is managed by the list of elements max-height property */}
                <Listbox.Options className="absolute z-50 mt-2 overflow-hidden -translate-x-1/2 bg-white shadow-lg drop-shadow-2xl w-72 left-1/2 rounded-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="flex flex-row items-center px-3 py-2 space-x-1 border-b-2 border-slate-200">
                    <SearchIcon className="w-6 h-6 fill-slate-500" />
                    <input
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                      className="w-full px-2 py-1 leading-tight text-gray-700 bg-transparent appearance-none focus:outline-none"
                      placeholder="Find a community..."
                    />
                  </div>
                  <div className="border-b border-slate-100">
                    <CustomListBoxOption community={MyWorkspace} />
                  </div>
                  {global && (
                    <div className="border-b shadow-md border-slate-100">
                      <CustomListBoxOption community={global} />
                    </div>
                  )}
                  <div className="overflow-scroll max-h-[35vh]">
                    {data?.communities &&
                      data.communities
                        .filter((community) => {
                          if (filter) return false;
                          if (community.slug === "global") return false;

                          return (
                            TopCommunitySlugs.indexOf(community.slug) !== -1
                          );
                        })
                        .sort((a, b) => {
                          const indexOfA = TopCommunitySlugs.indexOf(a.slug);
                          const indexOfB = TopCommunitySlugs.indexOf(b.slug);

                          return indexOfA - indexOfB;
                        })
                        .map((community) => {
                          return (
                            <CustomListBoxOption
                              key={community.communityId}
                              community={community}
                            />
                          );
                        })}
                    {data?.communities ? (
                      data.communities
                        .filter((community) => filterCommunities(community))
                        .map((community) => (
                          <CustomListBoxOption
                            key={community.communityId}
                            community={community}
                          />
                        ))
                    ) : (
                      <div className="flex flex-row py-8 space-x-4">
                        <div className="ml-4 font-semibold text-white text-md">
                          No Communities Found
                        </div>
                        <EmojiSadIcon className="w-5 h-5 text-white" />
                      </div>
                    )}
                  </div>
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </ClientOnly>
  );
};
