import { gql } from "@apollo/client";

export const CommunityQuery = gql`
  query Community($communitySlug: String!) {
    community(communitySlug: $communitySlug) {
      communityId
      slug
      name
      iconUrl
      tags
      createdAt
      updatedAt
      prefs {
        communityId
        isUserWorkspace
      }
      landingIcons {
        id
        name
        imageUrl
        linkUrl
        landingPreferencesId
      }
      landingPrefs {
        id
        backgroundImageUrl
        communityId
        createdAt
        _count {
          icons
        }
        updatedAt
      }
      isReader
      isWriter
      isModerator
      isAdmin
    }
  }
`;

export const CommunityTagsQuery = gql`
  query Community($communitySlug: String!) {
    community(communitySlug: $communitySlug) {
      tags
    }
  }
`;

export const CommunityTagsAndFoldersQuery = gql`
  query CommunityTagsAndFolders($communitySlug: String!) {
    community(communitySlug: $communitySlug) {
      tags
    }
    folders(communitySlug: $communitySlug) {
      folders {
        id
        name
      }
      hasMore
    }
  }
`;

// TODO: this isn't "real" pagination
export const CommunitySidebarQuery = gql`
  query SidebarQuery(
    $communitySlug: String!
    $pagination: PaginationArgs
    $language: String
  ) {
    folders(communitySlug: $communitySlug, language: $language) {
      folders {
        id
        name
        documents {
          documentId
          name
          slug
          folder {
            name
          }
          translations {
            translation {
              language
            }
            latestRevision {
              revision {
                createdAt
              }
            }
          }
        }
        translations {
          id
          language
          name
          description
        }
        parentFolder {
          name
        }
      }
      hasMore
    }
    documents(
      communitySlug: $communitySlug
      includeDocsWithFolders: false
      pagination: $pagination
      language: $language
    ) {
      documents {
        documentId
        name
        slug
        translations {
          translation {
            language
          }
          latestRevision {
            revision {
              createdAt
            }
          }
        }
      }
      hasMore
    }
    community(communitySlug: $communitySlug) {
      isWriter
      name
    }
  }
`;

export const CommunitiesQuery = gql`
  query Communities {
    communities {
      communityId
      slug
      name
      iconUrl
      prefs {
        communityId
      }
    }
  }
`;

export const AllowlistCommunityQuery = gql`
  query Community($communitySlug: String!) {
    community(communitySlug: $communitySlug) {
      allowlist {
        readers {
          address
        }
        writers {
          address
        }
        moderators {
          address
        }
        admins {
          address
        }
      }
    }
  }
`;

export const CommunityLandingBackgroundQuery = gql`
  query Query($communitySlug: String!) {
    community(communitySlug: $communitySlug) {
      landingPrefs {
        id
        backgroundImageUrl
      }
    }
  }
`;

export const CommunityLandingIcons = gql`
  query Query($communitySlug: String!) {
    community(communitySlug: $communitySlug) {
      landingIcons {
        id
        name
        imageUrl
        linkUrl
      }
    }
  }
`;
