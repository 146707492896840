import { gql } from "@apollo/client";

export const MeQuery = gql`
  query Me {
    me {
      name
      email
      address
      profilePictureUrl
      nonce
      userId
      reading {
        communityId
      }
      writing {
        communityId
      }
      moderating {
        communityId
      }
      admining {
        communityId
      }
      isSuperAdmin
    }
  }
`;

export const UserByAddressQuery = gql`
  query UserByAddress($address: String!) {
    userByAddress(address: $address) {
      name
      email
      address
      profilePictureUrl
      nonce
      userId
      reading {
        communityId
      }
      writing {
        communityId
      }
      moderating {
        communityId
      }
      admining {
        communityId
      }
      isSuperAdmin
    }
  }
`;
export const UserRevisions = gql`
  query Me($communitySlug: String!) {
    me {
      userRevisions(communitySlug: $communitySlug) {
        language
        document {
          name
          slug
          documentId
        }
        community {
          name
          slug
        }
        revision {
          id
          commitMessage
          createdAt
          updatedAt
          user {
            userId
            name
            email
            address
            profilePictureUrl
          }
        }
      }
    }
  }
`;
