import { MouseEventHandler } from "react";
import { Connector } from "wagmi";
import { ConnectMetaMask } from "./ConnectMetamask";
import { ConnectUnknown } from "./ConnectUnknown";
import { ConnectWalletConnect } from "./ConnectWalletConnect";
import { ConnectWalletLink } from "./ConnectWalletLink";

export function ConnectSwitch({
  connector,
  onClick,
}: {
  connector: Connector;
  onClick: MouseEventHandler;
}) {
  switch (connector.id) {
    case "injected":
      return <ConnectMetaMask onClick={onClick} />;
    case "walletConnect":
      return <ConnectWalletConnect onClick={onClick} />;
    case "walletLink":
      return <ConnectWalletLink onClick={onClick} connector={connector} />;
    default:
      return <ConnectUnknown onClick={onClick} name={connector.name} />;
  }
}
