import { MouseEventHandler } from "react";
import { ConnectBase } from "./ConnectBase";
import walletIcon from "./wallet.png";

export function ConnectUnknown({
  onClick,
  name,
}: {
  onClick: MouseEventHandler;
  name: string;
}) {
  return <ConnectBase name={name} imageUrl={walletIcon} onClick={onClick} />;
}
