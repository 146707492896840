import {
  Box,
  Button,
  FileInput,
  IconClose,
  Spinner,
  Stack,
  VisuallyHidden,
} from "degen";
import { useEffect, useRef, useState } from "react";

const _upload = async (file: File): Promise<string> => {
  const data = new FormData();
  data.append("file", file);
  const response = await fetch(
    "https://api.pinata.cloud/pinning/pinFileToIPFS",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_PINATA_JWT}`,
      },
      body: data,
    }
  );
  const dataFromPinata = await response.json();
  const url = `https://lobby-kb.mypinata.cloud/ipfs/${dataFromPinata.IpfsHash}`;
  return url;
};

// your upload function, takes a file, return a Promise<any>
export const uploadFile = async (file: File): Promise<string> => {
  try {
    return _upload(file);
  } catch (err) {
    console.warn("Error uploading the file : ", err);
    return "err";
  }
};

function useUploader(): [
  (file: File) => Promise<string | undefined>,
  { data: string; loading: boolean; error: string }
] {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function upload(file: File) {
    setLoading(true);
    try {
      const res = await _upload(file);
      setUrl(res);
      setLoading(false);
      return res;
    } catch (e: any) {
      setError(e?.message);
      setLoading(false);
    }
  }

  return [upload, { data: url, loading, error }];
}

export function FileUploader({
  onChange = () => {},
  resetter = 0,
}: {
  onChange: (url: string) => void;
  resetter?: any;
}) {
  const [upload, { data, loading, error }] = useUploader();
  const [oldResetter, setOldResetter] = useState(resetter);
  const resetButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (oldResetter !== resetter && resetButtonRef.current) {
      resetButtonRef.current?.click();
      setOldResetter(resetter);
    }
  }, [resetter]);

  async function onFileChange(file: File) {
    const url = await upload(file);

    if (url) {
      onChange(url);
    }
  }

  return (
    <>
      {error && (
        <div className="flex items-center justify-center w-full p-4 text-red-800 bg-red-100 rounded-xl">
          <span>{error}</span>
        </div>
      )}
      <FileInput onChange={(file) => onFileChange(file)}>
        {(context) =>
          context.name ? (
            <Stack align="center" direction="horizontal">
              {context.name}
              <Button
                ref={resetButtonRef}
                shape="circle"
                size="small"
                variant="transparent"
                onClick={context.reset}
              >
                <VisuallyHidden>Remove</VisuallyHidden>

                <IconClose />
              </Button>
              {loading && <Spinner />}
            </Stack>
          ) : (
            <Box>
              <div className="flex items-center justify-center w-full h-16 px-16 bg-gray-100 cursor-pointer rounded-xl hover:bg-gray-200">
                <span>{context.droppable ? "Drop file" : "Attach file"}</span>
              </div>
            </Box>
          )
        }
      </FileInput>
    </>
  );
}
