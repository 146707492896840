import Image from "next/image";
import React from "react";

export const Logo = () => {
  return (
    <div className="relative h-7 w-7">
      <Image layout="fill" alt="Lobby Logo" src="/icon.svg" />
    </div>
  );
};
