import Head from "next/head";
import React, { PropsWithChildren } from "react";
import { Header } from "../../organisms";

export const BaseLayout = ({
  children,
  headerStyle = {},
}: PropsWithChildren<{ headerStyle?: any }>) => {
  return (
    <div className="flex flex-col max-h-screen">
      <Head>
        <title>Lobby</title>
        <meta name="googlebot" content="noindex" />
        <meta name="robots" content="nosnippet" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <meta content={`Lobby`} property="og:title" />
        <meta content="article" property="og:type" />
        <meta content={`https://alpha.lobby.so`} property="og:url" />
        <meta
          content={`A community-run wiki for cryptocurrency, DeFi, and DAOs.`}
          property="og:description"
        />
        <meta content={`https://alpha.lobby.so/og.png`} property="og:image" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="@lobbyso" name="twitter:site" />
        <meta content={`Lobby`} name="twitter:title" />
        <meta
          content={`A community-run wiki for cryptocurrency, DeFi, and DAOs.`}
          name="twitter:description"
        />
        <meta content={`https://alpha.lobby.so/og.png`} name="twitter:image" />
      </Head>
      <Header style={headerStyle} />
      <a id="content"></a>
      {children}
    </div>
  );
};
