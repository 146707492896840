import {
  Document,
  Maybe,
  RevisionWithReport,
  User,
} from "../../../generated/graphql";
import { FormatDate } from "../../atoms";
import { ContributorModal } from "../../organisms";
interface EditingSidebarFooterProps {
  latestRevision: Maybe<RevisionWithReport> | undefined;
  document?: Document;
  contributors?: User[];
}
export function EditingSidebarFooter({
  latestRevision,
  document,
  contributors,
}: EditingSidebarFooterProps) {
  return (
    <div>
      {contributors && contributors.length > 0 ? (
        <ContributorModal contributors={contributors} />
      ) : (
        <></>
      )}
      {document?.createdAt ? (
        <div className="flex-col mb-2">
          <div className="font-semibold text-gray-400">CREATED AT</div>
          <p className="text-black">
            {/*Formatting the date logic*/}
            <FormatDate createdAt={String(document.createdAt)} />
          </p>
        </div>
      ) : (
        <></>
      )}
      {latestRevision?.revision.createdAt ? (
        <div className="flex-col">
          <div className="font-semibold text-gray-400">LAST UPDATED AT</div>
          <p className="text-black">
            {/*Formatting the date logic*/}
            <FormatDate createdAt={String(latestRevision.revision.createdAt)} />
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
