import { gql } from "@apollo/client";

export const SearchQuery = gql`
  query Search($query: String!, $communitySlug: String!, $documents: Float) {
    search(
      query: $query
      communitySlug: $communitySlug
      documents: $documents
    ) {
      searchId
      results {
        title
        uri
        snippet
        matchId
      }
    }
  }
`;

export const AnswerQuery = gql`
  query Answer($query: String!, $communitySlug: String!) {
    answer(query: $query, communitySlug: $communitySlug) {
      answer
      confidence
      answerId
      sources {
        title
        uri
      }
    }
  }
`;
