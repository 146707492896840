import { ChevronRightIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import Link from "next/link";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { slugToTitle } from "../../../lib/util/util";
import { CopyLinkButton } from "../CopyLinkButton/CopyLinkButton";

const BaseRedirectUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/"
    : "https://beta.lobby.so/";

interface BreadCrumbsProps {
  documentName?: string;
  documentId?: string;
  addPaddingX?: boolean;
}

export const BreadCrumbs = ({
  documentName,
  documentId,
  addPaddingX = true,
}: BreadCrumbsProps) => {
  const {
    asPath,
    pathname,
    communitySlug,
    documentSlug,
    moderatorName,
    adminPanelSlug,
  } = useLobbyRouter();

  var conditionalCrumb = <></>;

  const clickableStyle =
    "text-gray-500 border-b border-transparent hover:border-gray-200 hover:text-blue-600 cursor-pointer";

  if (asPath.includes("moderator")) {
    if (moderatorName === "Moderator") {
      conditionalCrumb = (
        <>
          <ChevronRightIcon className="w-4 h-4 text-gray-400" />
          <div> {moderatorName} </div>
        </>
      );
    } else {
      conditionalCrumb = (
        <>
          <ChevronRightIcon className="w-4 h-4 text-gray-400" />
          <Link href={`/${communitySlug}/moderator`}>
            <a className={clickableStyle}>Moderator</a>
          </Link>
          <ChevronRightIcon className="w-4 h-4 text-gray-400" />
          <div> {slugToTitle(moderatorName)} </div>
        </>
      );
    }
  } else if (asPath.includes("admin")) {
    if (adminPanelSlug === "") {
      conditionalCrumb = (
        <>
          <ChevronRightIcon className="w-4 h-4 text-gray-400" />
          <div>Admin</div>
        </>
      );
    } else {
      let adminTitle = "";

      if (adminPanelSlug === "landing") {
        adminTitle = "Appearance";
      } else if (adminPanelSlug === "access-control") {
        adminTitle = "Permissions";
      } else if (adminPanelSlug === "roles") {
        adminTitle = "Groups";
      } else if (adminPanelSlug === "tokens") {
        adminTitle = "Tokens";
      } else if (adminPanelSlug === "allowlist") {
        adminTitle = "Allowlist";
      }
      conditionalCrumb = (
        <>
          <ChevronRightIcon className="w-4 h-4 text-gray-400" />
          <Link href={`/${communitySlug}/admin`}>
            <a className={clickableStyle}>Admin</a>
          </Link>
          <ChevronRightIcon className="w-4 h-4 text-gray-400" />
          <div> {adminTitle} </div>
        </>
      );
    }
  } else {
    if (documentName) {
      if (documentName.includes("search?query")) {
        const parsedQuery = documentName.split("=")[1].replaceAll("+", " ");
        conditionalCrumb = (
          <>
            <ChevronRightIcon className="w-4 h-4 text-gray-400" />
            <div className="font-medium whitespace-nowrap">{parsedQuery}</div>
          </>
        );
      } else {
        conditionalCrumb = (
          <>
            <ChevronRightIcon className="w-4 h-4 text-gray-400" />
            <div className="font-medium whitespace-nowrap">
              {" "}
              {documentName}{" "}
            </div>
            {pathname !== "/[community]/new" && (
              <div className="flex items-center justify-center hover:cursor-pointer hover:outline-blue-400">
                <CopyLinkButton
                  link={`${BaseRedirectUrl}/documentId?id=${documentId}`}
                />
              </div>
            )}
          </>
        );
      }
    }
  }

  return (
    <div
      className={classNames(
        addPaddingX && "px-2",
        "flex flex-row items-center space-x-2 text-slate-700"
      )}
    >
      <Link href="/">
        <a className={clickableStyle}>Lobby</a>
      </Link>
      <ChevronRightIcon className="w-4 h-4 text-gray-400" />
      <Link href={`/${communitySlug}`}>
        <a className={documentSlug ? clickableStyle : ""}>
          {slugToTitle(communitySlug)}
        </a>
      </Link>
      {conditionalCrumb}
    </div>
  );
};
