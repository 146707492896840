import { Menu } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/outline";
import Link from "next/link";
import React, { useEffect } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { ConnectWalletDialog } from "../../organisms";
import { DotToolTip } from "../ToolTip/DotToolTip";

export const Profile = () => {
  const { token, signOut } = useAuthenticationContext();
  const { communitySlug, pathname } = useLobbyRouter();
  return (
    <>
      {token ? (
        <Menu as="div" className="relative inline-block">
          <Menu.Button>
            <UserCircleIcon
              className={
                `bg-transparent rounded-full h-7 w-7 ` +
                (pathname === "/" ? "stroke-black" : "stroke-white")
              }
            />
          </Menu.Button>
          <Menu.Items className="absolute right-0 z-50 w-56 mt-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg drop-shadow-2xls ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              <Link href={`/my-workspace`}>
                <a className="flex flex-row items-center px-4 py-2 text-gray-800 rounded-t-md hover:bg-indigo-500 hover:text-white hover:cursor-pointer">
                  My workspace
                  <div className="pl-3">
                    <DotToolTip content={`NEW!`} />
                  </div>
                </a>
              </Link>
            </Menu.Item>
            {communitySlug && (
              <Menu.Item>
                <Link href={`/${communitySlug}/account/revisions`}>
                  <a className="block px-4 py-2 text-gray-800 rounded-t-md hover:bg-indigo-500 hover:text-white hover:cursor-pointer">
                    My revisions
                  </a>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item>
              <Link href="/account">
                <a className="block px-4 py-2 text-gray-800 rounded-t-md hover:bg-indigo-500 hover:text-white hover:cursor-not-allowed">
                  Account settings
                </a>
              </Link>
            </Menu.Item>
            {token && (
              <Menu.Item>
                <Link href={`/create-a-wiki`}>
                  <a className="block px-4 py-2 text-gray-800 rounded-t-md hover:bg-indigo-500 hover:text-white hover:cursor-pointer">
                    Create a wiki
                  </a>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item>
              <a
                className="block px-4 py-2 text-gray-800 rounded-b-md hover:bg-red-500 hover:text-white hover:cursor-pointer"
                onClick={signOut}
              >
                Log out
              </a>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      ) : (
        <ConnectWalletDialog />
      )}
    </>
  );
};
