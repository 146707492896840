import { gql } from "@apollo/client";

export const CommunityTokensQuery = gql`
  query Query($communitySlug: String!) {
    findAllCommunityTokens(communitySlug: $communitySlug) {
      id
      name
      imageUrl
      network
      type
      tokenId
      address
      communityId
    }
  }
`;
