/* eslint-disable @next/next/no-img-element */

import { useLazyQuery } from "@apollo/client";
import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Image from "next/image";
import { useSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from "react";
import {
  Document as DocumentType,
  QueryDocumentBySlugArgs,
} from "../../../generated/graphql";
import client from "../../../lib/apollo/apollo-client";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { DocumentBySlugQuery } from "../../../lib/queries/Documents";
import { useDocumentListContext } from "../../layout/DocumentLayout/DocumentLayout";
import { Spinner } from "../Spinner/Spinner";
import { LanguageSelectorCircular } from "./LanguageSelectorCircular";
import { SupportedLanguage, supportedLanguages } from "./supportedLanguages";

interface LanguageSelectorProps {
  showCircularView: boolean;
  setViewing: Function;
  id?: string;
  setIsReported?: Function;
  selectedLocale?: SupportedLanguage;
}

export const LanguageSelector = ({
  showCircularView,
  setViewing,
  id,
  setIsReported,
  selectedLocale,
}: LanguageSelectorProps) => {
  /* circular view variables and functions*/
  const { replace, locale, pathname, communitySlug, documentSlug } =
    useLobbyRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [queryLocale, setQueryLocale] = useState(supportedLanguages[0]);
  const { refetchDocumentList } = useDocumentListContext();
  const [languageTranslationExists, setLanguageTranslationExists] = useState<
    string[]
  >([]);
  const setTranslationByURL = async (locale: string) => {
    await client.clearStore();
    await refetchDocumentList(locale);
    replace(
      `/${communitySlug}/${documentSlug}`,
      `/${communitySlug}/${documentSlug}`,
      { locale }
    );
  };

  /* circular view vars and functions end*/
  // TODO: loading needed for document header? or for the whole page? not sure
  //no need to get data here because the useEffect with refetch is going to run anyways
  const [getLatestRevision, { loading, error }] = useLazyQuery<
    { documentBySlug: DocumentType },
    QueryDocumentBySlugArgs
  >(DocumentBySlugQuery, {
    //when user searches from lobby search, the document slug contains "query=.." format; thus, failing
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (selectedLocale) {
      setQueryLocale(selectedLocale);
    } else {
      const lang = supportedLanguages.find((lang) => {
        return lang.locale === locale;
      });
      setQueryLocale(lang as SupportedLanguage);
    }
    //don't run on search query page, since it would be always failing because "Document doesn't exist"
    if (
      pathname !== "/[community]/search" &&
      communitySlug !== "[community]" &&
      pathname !== "/[community]/new" &&
      communitySlug !== "my-workspace"
    ) {
      getLatestRevision({
        variables: { communitySlug: communitySlug, slug: documentSlug },
      })
        .then((res) => {
          let languagesContainingTranslation: string[] = [];
          let translationExists = false;
          res.data?.documentBySlug?.translations.map((obj) => {
            //using locale here because queryLocal will not be updated in time, since setState for it is happening on line 68
            languagesContainingTranslation.push(obj.translation.language);
            if (
              obj.translation.language === (selectedLocale?.locale ?? locale)
            ) {
              if (setIsReported) {
                setIsReported(obj.latestRevision?.isReportedByUser ?? false);
              }
              translationExists = true;
              return;
            }
          });
          // if the translation for it doesn't exist, make it undefined, meaning the flag doesnt exist for those translations
          if (!translationExists && setIsReported !== undefined) {
            setIsReported(undefined);
          }
          setLanguageTranslationExists(languagesContainingTranslation);
        })
        .catch(() =>
          enqueueSnackbar("An error occured while fetching the revision.", {
            variant: "error",
          })
        );
    }
  }, [selectedLocale, locale]);

  if (loading) return <Spinner />;

  return (
    <div className="flex">
      {showCircularView ? (
        <LanguageSelectorCircular
          setTranslationByURL={setTranslationByURL}
          languageTranslationExists={languageTranslationExists}
        />
      ) : (
        <>
          <Listbox
            as="div"
            value={queryLocale}
            onChange={setQueryLocale}
            className="relative bg-white z-1"
            id={id}
          >
            <Listbox.Button className="bg-gray-300 rounded-lg justify-self-center">
              <div className="justify-between px-1 py-1 overflow-y-auto">
                <div className="flex justify-between">
                  <div className="relative w-8 h-8">
                    <Image
                      className="rounded-full"
                      layout="fill"
                      src={queryLocale.flagImageLink}
                      alt={queryLocale.locale}
                    />
                  </div>
                  <div className="px-2 py-1 font-medium">
                    {queryLocale.name}
                  </div>
                  <div className="w-8 ml-8 h-7">
                    <ChevronDownIcon />
                  </div>
                </div>
              </div>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 bg-gray-300 rounded-lg">
              {supportedLanguages.map((language, index) => (
                <Listbox.Option
                  key={language.id}
                  value={language}
                  as={Fragment}
                >
                  {({ active }) => (
                    <div
                      className={`${
                        active
                          ? "bg-blue-500 text-white"
                          : "bg-white text-black"
                      }`}
                    >
                      <button
                        className="justify-between px-1 py-1 overflow-y-auto"
                        onClick={() => {
                          setViewing(index);
                        }}
                      >
                        <div className="flex justify-between">
                          <div className="relative w-8 h-8">
                            <Image
                              className="rounded-full"
                              layout="fill"
                              src={language.flagImageLink}
                              alt={language.locale}
                            />
                          </div>
                          <div className="px-2 py-1 font-medium">
                            {language.name}
                          </div>
                          <div className="w-8 ml-8 h-7"></div>
                        </div>
                      </button>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </>
      )}
    </div>
  );
};
