import classNames from "classnames";
import { Spinner } from "../Spinner/Spinner";

interface ButtonProps {
  text: string;
  backgroundColor?: string;
  textColor?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

export const Button = ({
  text,
  backgroundColor = "bg-blue-600",
  textColor = "text-white",
  onClick,
  disabled = false,
  loading = false,
  className = "",
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        textColor,
        disabled ? "bg-gray-400" : backgroundColor,
        "relative rounded-xl px-4 py-2 font-medium hover:opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none",
        className
      )}
      onClick={() => {
        if (loading || disabled || !onClick) return;
        onClick();
      }}
      disabled={disabled}
    >
      {loading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center m-auto">
          <Spinner color={textColor || "white"} size="sm" />
        </div>
      )}
      <p className={classNames(loading && "invisible")}>{text}</p>
    </button>
  );
};
