import { StarIcon } from "@heroicons/react/solid";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GridViewIcon from "@mui/icons-material/GridView";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Link from "next/link";
import { ListItem } from "../../../interfaces";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";

const iconContainerStyle =
  "bg-blue-500 h-12 w-12 fill-white rounded-full flex items-center justify-center";
const iconStyle = "h-6 w-6 fill-white text-white";

export const AdminSidebarList: ListItem[] = [
  {
    name: "Main",
    slug: "",
    description: "",
    icon: <></>,
  },
  {
    name: "Appearance",
    slug: "landing",
    description: "Manage design and icons of community landing page",
    icon: (
      <div className={iconContainerStyle}>
        <GridViewIcon className={iconStyle} />
      </div>
    ),
  },
  {
    name: "Permissions",
    slug: "access-control",
    description: "Manage individual group access to folders",
    icon: (
      <div className={iconContainerStyle}>
        <LockOpenIcon className={iconStyle} />
      </div>
    ),
  },
  {
    name: "Groups",
    slug: "roles",
    description: "Create groups of different token holders",
    icon: (
      <div className={iconContainerStyle}>
        <GroupAddIcon className={iconStyle} />
      </div>
    ),
  },
  {
    name: "Tokens",
    slug: "tokens",
    description: "Manage community tokens that form groups",
    icon: (
      <div className={iconContainerStyle}>
        <CurrencyExchangeIcon className={iconStyle} />
      </div>
    ),
  },
  {
    name: "Allowlist",
    slug: "allowlist",
    description: "Add and remove individual wallets from Lobby",
    icon: (
      <div className={iconContainerStyle}>
        <StarIcon className={iconStyle} />
      </div>
    ),
  },
];

export const AdminSidebar = () => {
  const { adminPanelSlug, communitySlug, moderatorName } = useLobbyRouter();

  return (
    <div className="flex flex-col mt-4 overflow-hidden border-2 w-52 rounded-3xl">
      {AdminSidebarList.map((item) => {
        return (
          <Link key={item.name} href={`/${communitySlug}/admin/${item.slug} `}>
            <a
              className={`${
                item.name === moderatorName ||
                (item.slug === adminPanelSlug && moderatorName === "Moderator")
                  ? "bg-slate-200"
                  : ""
              } text-blue-500 hover:cursor-pointer py-3 px-5 hover:bg-gray-100`}
            >
              {item.name}
            </a>
          </Link>
        );
      })}
      <div className="h-56 " />
    </div>
  );
};
