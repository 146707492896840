import Mention from "@tiptap/extension-mention";
import { mergeAttributes } from "@tiptap/react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useLobbyEditor as useLobbyEditorPackage } from "lobby-editor";
import sanitizeHtml from "sanitize-html";
import { suggestion } from "../../components/atoms";
import { ImageUploader } from "../../components/atoms/TipTapComponents/DragNDropImage/ImageUploader";
import { uploadFile } from "./uploadFile";

export const useLobbyEditor = ({
  communitySlug,
  documentSlug,
  locale,
}: {
  communitySlug: string;
  documentSlug: string;
  locale: string | undefined;
}) => {
  let editor = useLobbyEditorPackage({
    customExtensions: [
      ImageUploader(uploadFile),
      Mention.extend({
        renderHTML({ node, HTMLAttributes }) {
          return [
            "a",
            mergeAttributes(
              { "data-type": this.name, href: node.attrs.id.uri },
              this.options.HTMLAttributes,
              HTMLAttributes
            ),
            this.options.renderLabel({
              options: this.options,
              node,
            }),
          ];
        },
        parseHTML() {
          return [{ tag: "a[href]" }];
        },
        addKeyboardShortcuts() {
          return {
            Backspace: () =>
              this.editor.commands.command(({ tr, state }) => {
                let isMention = false;
                const { selection } = state;
                const { empty, anchor } = selection;

                if (!empty) {
                  return false;
                }

                state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                  if (
                    node.type.name === this.name ||
                    (node.marks.length > 0 &&
                      node.marks[0].type.name === "link" &&
                      !node.text?.includes("http") &&
                      !node.text !== node.marks[0].attrs.href &&
                      node.text !== "[")
                  ) {
                    isMention = true;
                    tr.insertText(
                      this.options.suggestion.char || "",
                      pos,
                      pos + node.nodeSize
                    );

                    return false;
                  }
                });

                return isMention;
              }),
          };
        },
      }).configure({
        HTMLAttributes: {
          class: "mention",
        },
        renderLabel({ node }) {
          return `${node.attrs.id.title ?? node.attrs.id}`;
        },
        suggestion,
      }),
    ],
    // triggered on every change
    onContentUpdate: ({ editor }) => {
      //debounce
      editor && autoSaveDebounced(editor);
    },
  });

  if (!editor) return undefined;
  // Sets this for suggestions, will make a query using communitySlug
  // only way to access this is in the editor since suggestions
  // is not a functional compontent
  editor.storage.locale = locale ?? "en-US";
  editor.storage.communitySlug = communitySlug;
  editor.storage.documentSlug = documentSlug;
  return editor;
};

const autoSave = (editor: any) => {
  const html = sanitizeHtml(editor.getHTML(), {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
  });
  //get the language the current user is on
  const language = editor.storage.locale;
  const communitySlug = editor.storage.communitySlug;
  const documentSlug = editor.storage.documentSlug;

  if (communitySlug && documentSlug) {
    sessionStorage.setItem(
      getArticleStorageKey(language ?? "en-US", communitySlug, documentSlug),
      html
    );
  }
};

const autoSaveDebounced = AwesomeDebouncePromise(autoSave, 1000);

export function getArticleStorageKey(
  language: string,
  community: string,
  document: string
) {
  return `${community}/${document}/${language}`;
}
