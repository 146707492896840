import { useEnsLookup } from "wagmi";
import React, { useState } from "react";
import { copyText, trimEllip } from "../../../lib/util/util";
import { Tooltip } from "@mui/material";
import { ClipboardCopyIcon } from "@heroicons/react/solid";
import classNames from "classnames";

interface RevealAddressProps {
  address?: string;
  trimAddress?: boolean;
  hoverColor?: string;
  truncateProps?: string;
}

export const RevealAddress = ({
  address,
  trimAddress = true,
  hoverColor = "hover:bg-gray-100",
  truncateProps = "inline-flex",
}: RevealAddressProps) => {
  const [showFullAddress, setShowFullAddress] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [{ data }] = useEnsLookup({
    address: address,
  });

  function copyAddress(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    if (!address) return;
    copyText(address);
    setIsCopied(true);
  }

  return (
    <span
      onMouseLeave={() => {
        // Need this here otherwise it switches back to false immedietly and
        // it looks weird
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      }}
      className={classNames(
        data ? "hover:cursor-pointer" : "hover:cursor-text",
        hoverColor,
        truncateProps,
        "items-center p-1 space-x-1 rounded-sm group"
      )}
    >
      <span
        onClick={(e) => {
          e.preventDefault();
          setShowFullAddress(!showFullAddress);
        }}
        className="text-truncate"
      >
        {/* Defaults to "Author" if address is undefined,
         otherwise tries to display ens,
         if ens is undefined displays a trimmed address or full address
         */}
        {showFullAddress || !data
          ? trimAddress
            ? trimEllip(address ?? "")
            : address
          : trimEllip(data, 25)}
      </span>
      <Tooltip title={isCopied ? "Copied!" : "Copy to clipboard"}>
        <button
          className="opacity-0 group-hover:opacity-100"
          onClick={(e) => {
            copyAddress(e);
            setIsCopied(true);
          }}
        >
          <ClipboardCopyIcon className="w-5 h-5" />
        </button>
      </Tooltip>
    </span>
  );
};
