import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import {
  SupportedLanguage,
  supportedLanguages,
} from "../components/atoms/LanguageSelector/supportedLanguages";
import { isClient } from "../lib/util/util";

export interface PrefsContext {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  openFolders: { [communityAndFolder: string]: boolean };
  setOpenFolders: (key: string, isOpen: boolean) => void;
  openFoldersCommunityIndexPage: { [communityAndFolder: string]: boolean };
  setOpenFoldersCommunityIndexPage: (key: string, isOpen: boolean) => void;
  replaceOpenFolder: (oldKey: string, newKey: string) => void;
  userRevisionsOpenDocuments: { [communityAndDocument: string]: boolean };
  setUserRevisionsOpenDocuments: (key: string, isOpen: boolean) => void;
  createNewFolderId: string | null;
  setCreateNewFolderId: (folderId: string | null) => void;
  operandSessionId: string;
  currentMode: string;
  setCurrentMode: (newMode: string) => void;
  editingTranslationLocale: SupportedLanguage;
  setEditingTranslationLocale: (lang: SupportedLanguage) => void;
  showLandingBanner: boolean;
  hideLandingBanner: (hidden: boolean) => void;
}

export const PreferencesContext = createContext<PrefsContext>({
  isSidebarOpen: true,
  setIsSidebarOpen: (_isOpen: boolean) => {},
  openFolders: {},
  setOpenFolders: (_key: string, _isOpen: boolean) => {},
  openFoldersCommunityIndexPage: {},
  setOpenFoldersCommunityIndexPage: (_key: string, _isOpen: boolean) => {},
  replaceOpenFolder: (oldKey: string, newKey: string) => {},
  userRevisionsOpenDocuments: {},
  setUserRevisionsOpenDocuments: (_key: string, _isOpen: boolean) => {},
  createNewFolderId: null,
  setCreateNewFolderId: (folderId: string | null) => {},
  operandSessionId: "",
  currentMode: "",
  setCurrentMode: (newMode: string) => {},
  editingTranslationLocale: supportedLanguages[0],
  setEditingTranslationLocale: (lang: SupportedLanguage) => {},
  showLandingBanner: true,
  hideLandingBanner: (hidden: boolean) => {},
});

export const PreferencesProvider = ({ children }: PropsWithChildren<any>) => {
  const [hasMounted, setHasMounted] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  // Sets state so that the sidebar has an iput to write folder name
  const [createNewFolderId, setCreateNewFolderId] = useState<string | null>(
    null
  );
  const [operandSessionId, setOperandSessionId] = useState("");
  // Need this because sidebar gets rerendered when going between pages, so loses state
  // Keys are as follows "communitySlug,folderName"
  const [openFolders, setOpenFolders] = useState<{
    [communityAndFolder: string]: boolean;
  }>({});

  // Need to keep track of what folders are open on the community index page independant
  // from the sidebar
  const [openFoldersCommunityIndexPage, setOpenFoldersCommunityIndexPage] =
    useState<{
      [communityAndFolder: string]: boolean;
    }>({});

  const [userRevisionsOpenDocuments, setUserRevisionsOpenDocuments] = useState<{
    [communityAndDocument: string]: boolean;
  }>({});

  const [showLandingBanner, setHideLandingBanner] = useState(true);

  const [currentMode, setCurrentMode] = useState<string>("");
  const [editingTranslationLocale, setEditingTranslationLocale] =
    useState<SupportedLanguage>(supportedLanguages[0]);

  useEffect(() => {
    if (!hasMounted) {
      setOperandSessionId(uuidv4());
      setHasMounted(true);

      //init isSidebarOpen to the local preference, or init both to true
      const localIsSidebarOpen = localStorage.getItem("isSidebarOpen");
      if (localIsSidebarOpen == null) {
        localStorage.setItem("isSidebarOpen", JSON.stringify(true));
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(localIsSidebarOpen === "true");
      }

      const localOpenFolders = localStorage.getItem("openFolders");
      if (localOpenFolders) setOpenFolders(JSON.parse(localOpenFolders));

      const localOpenFoldersCommunityIndexPage = localStorage.getItem(
        "openFoldersCommunityIndexPage"
      );
      if (localOpenFoldersCommunityIndexPage)
        setOpenFoldersCommunityIndexPage(
          JSON.parse(localOpenFoldersCommunityIndexPage)
        );

      const localOpenUserRevisionDocuments = localStorage.getItem(
        "openUserRevisionDocuments"
      );
      if (localOpenUserRevisionDocuments)
        setUserRevisionsOpenDocuments(
          JSON.parse(localOpenUserRevisionDocuments)
        );
    }
  }, [hasMounted]);

  const provider = {
    isSidebarOpen,
    openFolders,
    openFoldersCommunityIndexPage,
    userRevisionsOpenDocuments,
    createNewFolderId,
    operandSessionId,
    currentMode,
    editingTranslationLocale,
    setEditingTranslationLocale: (lang: SupportedLanguage) => {
      setEditingTranslationLocale(lang);
    },

    // sets sidebar to open and saves to local storage
    setIsSidebarOpen: (isOpen: boolean) => {
      if (isClient())
        localStorage.setItem("isSidebarOpen", JSON.stringify(isOpen));
      setIsSidebarOpen(isOpen);
    },

    // opens a folder and updates it in local storage
    setOpenFolders: (key: string, isOpen: boolean) => {
      setOpenFolders((prev) => {
        const newOpenFolder = { ...prev };
        newOpenFolder[key] = isOpen;
        if (isClient())
          localStorage.setItem("openFolders", JSON.stringify(newOpenFolder));
        return newOpenFolder;
      });
    },
    // opens a folder and updates it in local storage for community index page
    setOpenFoldersCommunityIndexPage: (key: string, isOpen: boolean) => {
      setOpenFoldersCommunityIndexPage((prev) => {
        const newOpenFolder = { ...prev };
        newOpenFolder[key] = isOpen;
        if (isClient())
          localStorage.setItem(
            "openFoldersCommunityIndexPage",
            JSON.stringify(newOpenFolder)
          );
        return newOpenFolder;
      });
    },
    replaceOpenFolder: (oldKey: string, newKey: string) => {
      const localOpenFolders = localStorage.getItem("openFolders");
      if (localOpenFolders) {
        const obj = JSON.parse(localOpenFolders);
        if (obj) {
          delete obj[oldKey];
          obj[newKey] = true;
        }
        localStorage.setItem("openFolders", JSON.stringify(obj));
        setOpenFolders(obj);
      }
    },
    setUserRevisionsOpenDocuments: (key: string, isOpen: boolean) => {
      setUserRevisionsOpenDocuments((prev) => {
        const newOpenDocument = { ...prev };
        newOpenDocument[key] = isOpen;
        if (isClient())
          localStorage.setItem(
            "openUserRevisionDocuments",
            JSON.stringify(newOpenDocument)
          );
        return newOpenDocument;
      });
    },
    // serves as useState for new folder creation
    setCreateNewFolderId: (folderId: string | null) => {
      setCreateNewFolderId(folderId);
    },
    setCurrentMode: (newMode: string) => {
      setCurrentMode(newMode);
    },
    hideLandingBanner: (hidden: boolean) => {
      let landingBanner = window.sessionStorage.getItem("landingBanner");
      if (landingBanner) {
        setHideLandingBanner(hidden);
      }
      if (!landingBanner) {
        window.sessionStorage.setItem("landingBanner", "hide");
      }
    },
    showLandingBanner,
  };

  return (
    <PreferencesContext.Provider value={provider}>
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferencesContext = () => {
  return useContext<PrefsContext>(PreferencesContext);
};
