import { FolderAddIcon } from "@heroicons/react/solid";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import { usePreferencesContext } from "../../../contexts/PreferencesContext";
import { Document } from "../../../generated/graphql";
import { ReducedFolder } from "../../../interfaces";
import { useMixpanelCommunityEvent } from "../../../lib/hooks/mixpanel/useMixPanelCommunityEvent";
import { useFolderRoots } from "../../../lib/hooks/useFolderRoots";
import { useFunctionOnBottomOfElement } from "../../../lib/hooks/useFunctionOnBottomOfElement";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import {
  FileTree,
  FolderButton,
  Spinner,
  TreeDocumentComponent,
} from "../../atoms";
import { TreeInputComponent } from "../../atoms/FileTree/FileTree";
import { useDocumentListContext } from "../../layout/DocumentLayout/DocumentLayout";
import {
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useUpdateDocumentToFolderMutation,
  useUpdateFolderDataMutation,
  useUpdateParentFolderMutation,
} from "./hooks";

export const DocumentSidebar = () => {
  const {
    createNewFolderId,
    setCreateNewFolderId,
    currentMode,
    replaceOpenFolder,
  } = usePreferencesContext();
  const { enqueueSnackbar } = useSnackbar();

  const {
    documentListData,
    loading: documentListDataLoading,
    error,
    refetchDocumentList,
    fetchMoreDocumentList,
  } = useDocumentListContext();

  const { mixPanelFolderCreateEvent } = useMixpanelCommunityEvent();

  const { communitySlug, locale } = useLobbyRouter();
  const { token } = useAuthenticationContext();
  const [itemBeingDragged, setItemBeingDragged] = useState<
    ReducedFolder | Document | null
  >(null);
  const [itemBeingHovered, setItemBeingHovered] = useState<
    ReducedFolder | Document | null
  >(null);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const { folderRoots } = useFolderRoots({ documentListData });

  const [
    createFolder,
    {
      data: createFolderData,
      loading: createNewFolderLoading,
      error: createNewFolderError,
    },
  ] = useCreateFolderMutation();

  const [updateFolderData, { data: updatedFolderData }] =
    useUpdateFolderDataMutation();

  const [
    updateParentFolder,
    {
      data: updateParentFolderData,
      loading: updateParentFolderLoading,
      error: updateParentFolderError,
    },
  ] = useUpdateParentFolderMutation();

  const [
    updateDocumentToFolder,
    {
      data: updateDocumentToFolderData,
      loading: updateDocumentToFolderLoading,
      error: updateDocumentToFolderError,
    },
  ] = useUpdateDocumentToFolderMutation();

  const [
    deleteFolder,
    {
      data: deletedFolderData,
      loading: folderDeletingLoading,
      error: deleteFolderError,
    },
  ] = useDeleteFolderMutation();

  const loading = useMemo(() => {
    return (
      documentListDataLoading ||
      updateParentFolderLoading ||
      updateDocumentToFolderLoading ||
      folderDeletingLoading ||
      createNewFolderLoading
    );
  }, [
    documentListDataLoading,
    documentListData,
    updateParentFolderLoading,
    updateDocumentToFolderLoading,
    folderDeletingLoading,
    createNewFolderLoading,
  ]);

  const { debouncedFunctionAtEndOfElement } = useFunctionOnBottomOfElement({
    ref: sidebarRef,
    func: fetchMoreDocumentList,
  });

  useEffect(() => {
    if (updateDocumentToFolderError || updateParentFolderError) {
      const error =
        updateDocumentToFolderError?.message ??
        updateParentFolderError?.message;

      if (
        error &&
        (error.includes("Not signed in as writer.") ||
          error.includes("User needs to be logged in for this action."))
      )
        enqueueSnackbar("Need to be a writer to do move folders", {
          variant: "error",
        });
      else {
        enqueueSnackbar("Error occurred moving folders.", { variant: "error" });
      }
    }
    createNewFolderError?.message &&
      enqueueSnackbar(`${createNewFolderError.message}`, { variant: "error" });

    deleteFolderError?.message &&
      enqueueSnackbar(`${deleteFolderError.message}`, { variant: "error" });
  }, [
    updateDocumentToFolderError,
    updateParentFolderError,
    createNewFolderError,
    deleteFolderError,
    enqueueSnackbar,
  ]);

  // reload sidebar if auth state changes
  useEffect(() => {
    refetchDocumentList(locale);
  }, [token, refetchDocumentList, locale]);

  useEffect(() => {
    if (
      updateParentFolderData?.updateParentFolder ||
      updateDocumentToFolderData?.updateDocumentToFolder ||
      createFolderData?.createFolder ||
      updatedFolderData?.updateFolderData ||
      deletedFolderData?.deleteFolder
    ) {
      refetchDocumentList(locale);
    }
  }, [
    updateParentFolderData?.updateParentFolder,
    updateDocumentToFolderData?.updateDocumentToFolder,
    createFolderData?.createFolder,
    updatedFolderData?.updateFolderData,
    deletedFolderData?.deleteFolder,
    refetchDocumentList,
    locale,
  ]);

  const onDragEnd = () => {
    if (loading || updateParentFolderLoading || updateDocumentToFolderLoading)
      return;
    if (itemBeingDragged === null || itemBeingHovered === null) {
      console.error("Item being dragged or hovered is null.");
      return;
    }

    // Checks if object implements document
    // Only need to check whether it is a doc or folder because
    // there are only 2 types
    const isDocument = (item: ReducedFolder | Document) => {
      return item.hasOwnProperty("slug");
    };

    // When document over another document set dragging doc parent to hover doc parent
    if (isDocument(itemBeingDragged) && isDocument(itemBeingHovered)) {
      // No-op if same document
      if (
        (itemBeingDragged as Document).slug ===
        (itemBeingHovered as Document).slug
      )
        return;
      updateDocumentToFolder({
        variables: {
          communitySlug,
          slug: (itemBeingDragged as Document).slug,
          folderName: (itemBeingHovered as Document).folder?.name ?? "",
        },
      });
    }

    // When folder over document set folder parent to document parent
    if (!isDocument(itemBeingDragged) && isDocument(itemBeingHovered)) {
      updateParentFolder({
        variables: {
          communitySlug,
          folderName: (itemBeingDragged as ReducedFolder).name,
          parentFolderName: (itemBeingHovered as Document).folder?.name ?? "",
        },
      });
    }

    // When document over folder set document parent to folder
    if (isDocument(itemBeingDragged) && !isDocument(itemBeingHovered)) {
      updateDocumentToFolder({
        variables: {
          communitySlug,
          slug: (itemBeingDragged as Document).slug,
          folderName: (itemBeingHovered as ReducedFolder).name,
        },
      });
    }

    // When folder over another folder set dragging folder parent to hover parent
    if (!isDocument(itemBeingDragged) && !isDocument(itemBeingHovered)) {
      // No-op if same folder
      if (
        (itemBeingDragged as ReducedFolder).name ===
        (itemBeingHovered as ReducedFolder).name
      )
        return;
      updateParentFolder({
        variables: {
          communitySlug,
          folderName: (itemBeingDragged as ReducedFolder).name,
          parentFolderName: (itemBeingHovered as ReducedFolder)?.name ?? "",
        },
      });
    }

    setItemBeingDragged(null);
    setItemBeingHovered(null);
  };

  const createNewFolder = (
    name: string,
    language: string,
    parentFolderName?: string
  ) => {
    createFolder({
      variables: {
        communitySlug,
        name,
        parentFolderName,
        language,
      },
    }).then(() => {
      mixPanelFolderCreateEvent(name, parentFolderName ?? "", language);
    });
  };

  const updateFolderName = (
    name: string,
    newName: string,
    language: string
  ) => {
    updateFolderData({
      variables: {
        communitySlug,
        folderName: name,
        newName,
        language,
      },
    }).catch((error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    });
    replaceOpenFolder(
      `${communitySlug},${name}`,
      `${communitySlug},${newName}`
    );
  };

  const deleteCurrentFolder = (folderName: string) => {
    deleteFolder({
      variables: {
        folderName,
        communitySlug,
      },
    });
  };

  if (error?.message.includes("Not signed in as reader."))
    return (
      <div className="flex flex-col items-center justify-center px-3 py-2 overflow-scroll font-semibold w-96 bg-slate-50 border-x">
        <p className="text-center">You are not a reader in this community.</p>
      </div>
    );
  return (
    <div
      onDragEnd={onDragEnd}
      onWheel={debouncedFunctionAtEndOfElement}
      className="flex flex-col h-screen overflow-hidden w-72 bg-slate-50 border-x"
    >
      {loading && !documentListData ? (
        <div className="flex flex-col justify-center flex-grow">
          <Spinner />
        </div>
      ) : (
        <div ref={sidebarRef} className="overflow-scroll">
          <div className="flex items-center justify-between py-1 pl-6 pr-2 overflow-scroll bg-gray-200">
            <h3 className="text-sm font-semibold font-gray-700">
              {documentListData?.community?.name}
            </h3>
            {documentListData?.community?.isWriter && (
              <div>
                <FolderButton
                  onClick={() => {
                    setCreateNewFolderId("");
                  }}
                >
                  <FolderAddIcon />
                </FolderButton>
              </div>
            )}
          </div>
          <div className="flex flex-col flex-grow">
            {folderRoots.map((root) => {
              return (
                <FileTree
                  setItemBeingDragged={(
                    el: ReducedFolder | Document | null
                  ) => {
                    setItemBeingDragged(el);
                  }}
                  setItemBeingHovered={(
                    el: ReducedFolder | Document | null
                  ) => {
                    setItemBeingHovered(el);
                  }}
                  itemBeingHovered={itemBeingHovered}
                  key={root.name}
                  createFolder={createNewFolder}
                  updateFolderName={updateFolderName}
                  rootFolder={root}
                  isWriter={documentListData?.community?.isWriter ?? false}
                  deleteFolder={deleteCurrentFolder}
                />
              );
            })}
            {createNewFolderId === "" && (
              <TreeInputComponent
                isInContext={false}
                createFolder={createNewFolder}
              />
            )}
            {documentListData?.documents?.documents.map((document) => {
              return (
                <TreeDocumentComponent
                  key={document.slug}
                  isInContext={false}
                  document={document}
                  href={`/${communitySlug}/${document.slug}/${currentMode}`}
                  itemBeingHovered={itemBeingHovered}
                  setItemBeingDragged={(
                    el: ReducedFolder | Document | null
                  ) => {
                    setItemBeingDragged(el);
                  }}
                  setItemBeingHovered={(
                    el: ReducedFolder | Document | null
                  ) => {
                    setItemBeingHovered(el);
                  }}
                />
              );
            })}
            {loading && (
              <div className="m-10 ">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
