import React from "react";

interface CommitMessageInputProps {
  placeholder?: string;
  firstCommit?: boolean;
  onChange: (e: string) => void;
  flex?: boolean;
}

export const CommitMessageInput = ({
  placeholder = "Describe your changes...",
  firstCommit = false,
  onChange,
}: CommitMessageInputProps) => {
  const firstCommitMessage = "Created Document";
  if (firstCommit) onChange(firstCommitMessage);

  return (
    <input
      className="flex w-full px-4 py-2 border rounded-xl"
      placeholder={placeholder}
      defaultValue={firstCommit ? firstCommitMessage : ""}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};
