import { format } from "date-fns";

interface FormatDateProps {
  createdAt: string;
}

export function FormatDate({ createdAt }: FormatDateProps) {
  return (
    <>
      {format(new Date(Date.parse(createdAt)), `MMM D, YYYY h:mma `)}
      {/*Displaying the timeZone logic*/}
      {new Date()
        .toLocaleDateString(undefined, {
          day: "2-digit",
          timeZoneName: "short",
        })
        .substring(4)}
    </>
  );
}
