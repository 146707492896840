import { LinkIcon } from "@heroicons/react/outline";
import { ClipboardCopyIcon } from "@heroicons/react/solid";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { copyText } from "../../../lib/util/util";

interface CopyLinkButtonProps {
  link: string;
  hoverColor?: string;
}

export const CopyLinkButton = ({
  link,
  hoverColor = "hover:bg-gray-100",
}: CopyLinkButtonProps) => {
  const [showFullAddress, setShowFullAddress] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  function copyAddress() {
    copyText(link);
    setIsCopied(true);
  }

  return (
    <div
      onMouseLeave={() => {
        // Need this here otherwise it switches back to false immedietly and
        // it looks weird
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      }}
    >
      <Tooltip title={isCopied ? "Copied!" : "Copy to clipboard"}>
        <button
          onClick={(e) => {
            e.preventDefault();

            copyAddress();
            setIsCopied(true);
          }}
          className={classNames(
            hoverColor,
            "cursor-pointer rounded-lg flex items-center justify-center h-8 w-8 p-1  group"
          )}
        >
          <LinkIcon className="w-4 h-4" />
        </button>
      </Tooltip>
    </div>
  );
};
