import classNames from "classnames";
import Image from "next/image";
import React from "react";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { useDocumentListContext } from "../../layout/DocumentLayout/DocumentLayout";
import { ToolTip } from "../ToolTip/ToolTip";
import styles from "./LanguageSelector.module.scss";
import { supportedLanguages } from "./supportedLanguages";

interface LanguageSelectorCircularProps {
  setTranslationByURL: (locale: string) => void;
  languageTranslationExists: string[];
}

export function LanguageSelectorCircular({
  setTranslationByURL,
  languageTranslationExists,
}: LanguageSelectorCircularProps) {
  const { pathname, locale: selectedLocale } = useLobbyRouter();
  return (
    <div
      className={classNames(
        "flex flex-row pl-4 -space-x-3  hover:space-x-2  focus-within:space-x-2",
        styles.languageGroup
      )}
    >
      {supportedLanguages.map(
        ({ locale, flagImageLink, name, nameNative }, index) => {
          return (
            <ToolTip
              delay={100}
              animation="shift"
              hideDelay={0}
              size="small"
              className="transition-all duration-150"
              key={locale}
              content={nameNative ? `${nameNative} (${name})` : name}
              position="bottom"
              sticky={true}
            >
              <button onClick={() => setTranslationByURL(locale)}>
                <div
                  className={classNames(
                    selectedLocale === locale && "border-green-500",
                    "relative w-8 h-8 bg-white border-2 border-separate rounded-full shadow mix-blend-color-dodge hover:border-blue-300 shadow-gray-400"
                  )}
                >
                  <Image
                    className={classNames(
                      pathname !== "/[community]/search" &&
                        pathname !== "/[community]" &&
                        pathname !== "/[community]/new"
                        ? languageTranslationExists.includes(locale)
                          ? ""
                          : "opacity-40"
                        : "",
                      "rounded-full"
                    )}
                    layout="fill"
                    src={flagImageLink}
                    alt={locale}
                  />
                </div>
              </button>
            </ToolTip>
          );
        }
      )}
    </div>
  );
}
