import { gql } from "@apollo/client";

export const CreateDocumentMutation = gql`
  mutation CreateDocument(
    $content: String!
    $commitMessage: String!
    $name: String!
    $communitySlug: String!
    $slug: String!
    $language: String!
    $tags: [String!]
    $folderName: String
  ) {
    createDocument(
      content: $content
      commitMessage: $commitMessage
      name: $name
      communitySlug: $communitySlug
      slug: $slug
      language: $language
      tags: $tags
      folderName: $folderName
    ) {
      documentId
      slug
    }
  }
`;

export const UpdateDocumentMutation = gql`
  mutation UpdateDocument(
    $name: String
    $tags: [String!]!
    $folderName: String!
    $content: String!
    $commitMessage: String!
    $communitySlug: String!
    $slug: String!
    $language: String!
  ) {
    updateDocument(
      tags: $tags
      name: $name
      folderName: $folderName
      content: $content
      commitMessage: $commitMessage
      communitySlug: $communitySlug
      slug: $slug
      language: $language
    ) {
      slug
      documentId
    }
  }
`;

export const UpdateDocumentToFolderMutation = gql`
  mutation UpdateDocumentToFolder(
    $folderName: String!
    $slug: String!
    $communitySlug: String!
  ) {
    updateDocumentToFolder(
      folderName: $folderName
      slug: $slug
      communitySlug: $communitySlug
    ) {
      documentId
      name
    }
  }
`;

export const BulkHideDocumentsMutation = gql`
  mutation BulkHideDocuments($communitySlug: String!, $slugs: [String!]!) {
    bulkHideDocuments(communitySlug: $communitySlug, slugs: $slugs) {
      documentId
      slug
    }
  }
`;
