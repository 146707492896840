import { SearchIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { SearchBar } from "../../atoms";

export const SearchHeader = () => {
  const [value, setValue] = useState("");
  const { communitySlug, push } = useLobbyRouter();
  const [search, setSearch] = useState(false);
  const newPath = `/${communitySlug}/search`;

  const setSearchValue = (val: string) => {
    setValue(val);
  };

  const submit = (e: React.FormEvent) => {
    e?.preventDefault();
    push({
      pathname: newPath,
      query: { query: value, origin: "Community navbar" },
    });
  };

  return (
    <>
      <form onSubmit={(e) => submit(e)}>
        <div className="hidden lg:block md:block items-center w-48 py-1 text-sm transition-[width] bg-white bg-opacity-30 focus-within:bg-opacity-100 placeholder:text-white focus-within:w-72 rounded-xl">
          <SearchBar
            setValue={setSearchValue}
            className="w-full px-1 mx-2 text-white bg-transparent outline-none placeholder:text-white focus:placeholder:text-gray-400 focus:text-gray-800"
          />
        </div>
      </form>
      <form
        className="lg:hidden md:hidden sm:block"
        onSubmit={(e) => submit(e)}
      >
        <div className="grid grid-flow-col-dense items-center justify-center pr-2 py-0.5 bg-white/30 rounded-md">
          <SearchBar
            setValue={setSearchValue}
            className="w-12 pl-2 py-0 bg-transparent outline-none 
            focus:bg-white focus:rounded-xl focus:transition-[width] focus:placeholder:text-gray-400 focus:text-gray-800 focus-within:w-64
            focus:pl-16 focus:pr-4 focus:z-50"
          />
          <SearchIcon className="w-5 h-5 text-white/80" />
        </div>
      </form>
    </>
  );
};
