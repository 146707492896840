import { useMutation } from "@apollo/client";
import {
  Document,
  Folder,
  MutationCreateFolderArgs,
  MutationUpdateDocumentToFolderArgs,
  MutationUpdateParentFolderArgs,
  MutationUpdateFolderDataArgs,
} from "../../../generated/graphql";
import { UpdateDocumentToFolderMutation } from "../../../lib/mutations/Documents";
import {
  CreateFolderMutation,
  DeleteFolderMutation,
  UpdateParentFolderMutation,
  UpdateFolderData,
} from "../../../lib/mutations/Folders";

export function useUpdateParentFolderMutation() {
  return useMutation<
    { updateParentFolder: Folder },
    MutationUpdateParentFolderArgs
  >(UpdateParentFolderMutation);
}

export function useUpdateDocumentToFolderMutation() {
  return useMutation<
    { updateDocumentToFolder: Document },
    MutationUpdateDocumentToFolderArgs
  >(UpdateDocumentToFolderMutation);
}

export function useCreateFolderMutation() {
  return useMutation<{ createFolder: Folder }, MutationCreateFolderArgs>(
    CreateFolderMutation
  );
}

export function useUpdateFolderDataMutation() {
  return useMutation<
    { updateFolderData: Folder },
    MutationUpdateFolderDataArgs
  >(UpdateFolderData);
}

export function useDeleteFolderMutation() {
  return useMutation<{
    deleteFolder: Folder;
  }>(DeleteFolderMutation);
}
