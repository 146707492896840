import { gql } from "@apollo/client";

export const CreateReportMutation = gql`
  mutation CreateReport($revisionId: String!, $communitySlug: String!) {
    createReport(revisionId: $revisionId, communitySlug: $communitySlug) {
      id
      revisionId
      userId
      createdAt
    }
  }
`;

export const DeleteReportMutation = gql`
  mutation DeleteReport($revisionId: String!, $communitySlug: String!) {
    deleteReport(revisionId: $revisionId, communitySlug: $communitySlug) {
      id
      revisionId
      userId
      createdAt
    }
  }
`;
