import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, { FormEvent, useState } from "react";
import { Role, Token } from "../../../generated/graphql";
import { TokenSelector } from "../../atoms";

interface CommunityRoleProps {
  role: Role;
  onSave: (e: FormEvent<HTMLFormElement>, token: Token, roleId: string) => void;
}

export const CommunityRole = ({ role, onSave }: CommunityRoleProps) => {
  const [currentToken, setCurrentToken] = useState<Token>();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          if (!currentToken) {
            enqueueSnackbar("Please assign a token to your role", {
              variant: "error",
            });
          } else {
            onSave(e, currentToken, role.id);
          }
        }}
        autoComplete="off"
      >
        <div className="min-w-full p-3 my-1 border-2 rounded-xl">
          <div className="flex flex-row items-center justify-center space-x-1">
            <div
              className={`w-10 h-5 rounded-full ${
                role.color === "" ? "bg-blue-500" : role.color
              } mr-5`}
            ></div>
            <input
              id="name"
              className={classNames(
                role.name === "New Role"
                  ? "placeholder-blue-400"
                  : "placeholder-blue-500",
                "w-48 outline-none basis-5/6 pr-5 font-semibold text-blue-500 py-2"
              )}
              autoComplete="off"
              placeholder={role.name === "New Role" ? "New Role" : ""}
              defaultValue={role.name === "New Role" ? "" : role.name}
            />
            <input
              id="threshold"
              type="number"
              step={
                currentToken?.type?.toLowerCase() === "erc-20" ? ".01" : "1"
              }
              className="p-1 mr-1 placeholder-black border-2 rounded-xl w-28"
              autoComplete="off"
              defaultValue={Number(role.threshold)}
              min="0"
              onKeyPress={(e) => {
                if (e.code === "Minus") {
                  e.preventDefault();
                  enqueueSnackbar(
                    "Can not apply negative threshold values to roles",
                    {
                      variant: "info",
                    }
                  );
                }
              }}
            />
            <TokenSelector
              existingTokenId={role.tokenId ?? ""}
              setTokenInForm={setCurrentToken}
              currentToken={currentToken}
              setCurrentToken={(token) => setCurrentToken(token)}
            />
            <button className="w-20 px-4 py-2 font-medium text-white bg-blue-600 justify-item-end rounded-xl hover:opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none">
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
