import { MittEmitter } from "next/dist/shared/lib/mitt";
import { NextRouter, useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { UrlObject } from "url";
import {
  parseAdminPanelSlug,
  parseCommunitySlug,
  parseDocumentSlug,
  parseModeratorName,
} from "../util/util";

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}
interface LobbyRouter extends NextRouter {
  communitySlug: string;
  documentSlug: string;
  moderatorName: string;
  adminPanelSlug: string;
  isCommunityIndexPage: boolean;
  locale: string;
}

function useLobbyRouter(): LobbyRouter {
  const router = useRouter();

  let isCommunityIndexPage = false;
  if (router.asPath.split("/").length === 2) isCommunityIndexPage = true;

  const communitySlug: string = parseCommunitySlug(router.asPath);
  const documentSlug: string = parseDocumentSlug(router.asPath);
  const moderatorName: string = parseModeratorName(router.asPath);
  const adminPanelSlug: string = parseAdminPanelSlug(router.asPath);
  const locale: string = router.locale ?? "en-US";

  return {
    ...router,
    locale,
    communitySlug,
    documentSlug,
    moderatorName,
    adminPanelSlug,
    isCommunityIndexPage,
  };
}

export default useLobbyRouter;
