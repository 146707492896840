import { MouseEventHandler } from "react";
import { ConnectBase } from "./ConnectBase";
import walletConnectIcon from "./walletconnect.svg";

export function ConnectWalletConnect({
  onClick,
}: {
  onClick: MouseEventHandler;
}) {
  return (
    <ConnectBase
      name="WalletConnect"
      imageUrl={walletConnectIcon}
      onClick={onClick}
    />
  );
}
