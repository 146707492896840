import React from "react";
import { ToolTip } from "./ToolTip";

export const DotToolTip = ({ content }: { content: string }) => {
  return (
    <ToolTip contentClassnames="text-xs" content={content}>
      <div className="w-2 h-2 bg-orange-400 rounded-full hover:cursor-default" />
    </ToolTip>
  );
};
