import classNames from "classnames";
import { useState } from "react";

interface InputDropdownProps {
  className: string;
  label?: string;
  placeholder?: string;
  dropdownList?: string[];
  input: string;
  setInput: (str: string) => void;
  dropdownPlaceholder: string;
  onSubmit: (str: string) => void;
  clearInput?: boolean;
}

export const InputDropdown = ({
  className,
  label,
  placeholder,
  dropdownList,
  input,
  setInput,
  dropdownPlaceholder,
  onSubmit,
  clearInput = true,
}: InputDropdownProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const onInputSubmit = () => {
    onSubmit(input);
    if (clearInput) {
      setInput("");
    }
    setIsFocused(false);
  };

  const onDropdownClick = (str: string) => {
    onSubmit(str);
    setInput(clearInput ? "" : str);
    setIsFocused(false);
  };

  return (
    <div className="relative">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onInputSubmit();
          setIsFocused(false);
        }}
      >
        <div className="mt-4 mb-2 text-sm font-medium">
          <label className="block mb-1 ml-3">{label}</label>
          <input
            className={className}
            placeholder={placeholder}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            value={input}
            onChange={(e) => {
              const str = e.target.value;
              setInput(str);

              if (str.includes(" ")) {
                onInputSubmit();
                setIsFocused(false);
              }
            }}
          />
        </div>
      </form>
      <div
        className={classNames(
          `${isFocused ? "visible divide-y border" : "hidden"}`,
          "absolute z-50 text-sm w-full rounded-md mt-1 bg-white"
        )}
      >
        {!dropdownList || dropdownList.length === 0 ? (
          <>
            {!input && <div className="px-4 py-2">{dropdownPlaceholder}</div>}
          </>
        ) : (
          <>
            {dropdownList
              .filter((item) => {
                return item.toLowerCase().includes(input.toLowerCase());
              })
              .map((item) => {
                return (
                  <div
                    key={item}
                    onMouseDown={() => {
                      onDropdownClick(item);
                    }}
                    className="px-4 py-2 hover:bg-slate-100 hover:cursor-pointer"
                  >
                    {item}
                  </div>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};
