import Image, { StaticImageData } from "next/image";
import { MouseEventHandler } from "react";

export interface ConnectProps {
  onClick: MouseEventHandler;
  name: string;
  imageUrl: string | StaticImageData;
}

export function ConnectBase({ onClick, name, imageUrl }: ConnectProps) {
  return (
    <div className="relative m-2">
      <button
        onClick={onClick}
        className="flex flex-col items-center justify-center w-full p-8 border border-gray-100 cursor-pointer hover:border-gray-200 active:bg-gray-200 active:border-gray-300 rounded-xl hover:bg-gray-100"
      >
        <div className="mb-8">
          <Image src={imageUrl} alt="" height={128} width={128} />
        </div>
        <div className="font-medium text-gray-700 text-md">{name}</div>
      </button>
    </div>
  );
}
