import { useQuery } from "@apollo/client";
import { Listbox, Transition } from "@headlessui/react";
import { UserGroupIcon } from "@heroicons/react/outline";
import {
  ArrowCircleRightIcon,
  CheckIcon,
  ChevronDownIcon,
  EmojiSadIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import Link from "next/link";
import React, { Fragment, useEffect, useState } from "react";
import {
  QueryFindAllCommunityTokensArgs,
  Token,
} from "../../../generated/graphql";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { CommunityTokensQuery } from "../../../lib/queries/Tokens";
import { ClientOnly } from "../ClientOnly/ClientOnly";

interface TokenSelectorProps {
  existingTokenId?: string;
  setTokenInForm: (token: Token) => void;
  currentToken?: Token;
  setCurrentToken: (token: Token) => void;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  textColor?: string;
}

//placeholder data for roles w/o a set token
export const DefaultNewToken: Token = {
  name: " ",
  network: "mainnet",
  address: "0x0000000000000000000000000000",
  type: "erc-20",
  imageUrl: "/assets/wallet-icon/wallet-icon.svg",
  communityId: "global",
  id: "1",
  createdAt: "1",
  updatedAt: "1",
  decimals: 18,
};

const CustomListBoxOption = ({ token }: { token: Token }): JSX.Element => {
  return (
    <Listbox.Option
      className={({ active }) =>
        classNames(
          active ? "text-gray-900  bg-gray-200 " : "text-gray-900  ", // dark:text-gray-200
          "cursor-pointer select-none relative py-2 pl-3 pr-9"
        )
      }
      value={token}
    >
      {({ selected, active }) => (
        <>
          <div className="flex items-center">
            <div className="flex-shrink-0 w-6 h-6 rounded-full">
              {token.imageUrl ? (
                // does not need alt because it's for display only
                <img className="rounded-full" alt="" src={token.imageUrl} />
              ) : (
                <UserGroupIcon />
              )}
            </div>

            <span
              className={classNames(
                selected ? "font-semibold" : "font-normal",
                "ml-3 block truncate"
              )}
            >
              {token.name}
            </span>
          </div>

          {selected ? (
            <span
              className={classNames(
                "absolute inset-y-0 right-0 flex text-blue-600 items-center pr-4"
              )}
            >
              <CheckIcon aria-hidden="true" className="w-5 h-5" />
            </span>
          ) : (
            <></>
          )}
        </>
      )}
    </Listbox.Option>
  );
};

export const TokenSelector = ({
  existingTokenId,
  setTokenInForm,
  currentToken,
  setCurrentToken,
  backgroundColor = "bg-transparent",
  hoverBackgroundColor = "bg-transparent",
  textColor = "text-black",
}: TokenSelectorProps) => {
  // router
  const { push, asPath, communitySlug } = useLobbyRouter();
  //get all community tokens
  const { data } = useQuery<
    { findAllCommunityTokens: Token[] },
    QueryFindAllCommunityTokensArgs
  >(CommunityTokensQuery, {
    variables: { communitySlug: communitySlug },
  });
  // selected token
  if (!currentToken) setCurrentToken(DefaultNewToken);

  //once the data is loaded, get the token id's and match it with existing one
  useEffect(() => {
    data?.findAllCommunityTokens.find((token) => {
      if (token.id === existingTokenId) {
        setCurrentToken(token);
      }
    });
  }, [data]);

  const [filter, setFilter] = useState<string>("");

  const onSelectHandler = (value: Token) => {
    setCurrentToken(value);
    setTokenInForm(value);
    setFilter("");
  };

  const filterTokens = (token: Token) => {
    return token.name.toLowerCase().includes(filter.toLowerCase());
  };

  return (
    <ClientOnly>
      <Listbox value={currentToken} onChange={onSelectHandler}>
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">Select a Token</Listbox.Label>
            <div className="relative">
              <Listbox.Button
                className={classNames(
                  "border-2 rounded-xl w-32 relative py-1 pl-3 pr-10 text-left shadow-sm hover:cursor-pointer",
                  backgroundColor,
                  hoverBackgroundColor
                )}
              >
                <span className="flex items-center">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full">
                    {currentToken?.imageUrl ? (
                      // does not need alt because it is for display only
                      <img
                        className="rounded-full"
                        alt=""
                        src={currentToken.imageUrl}
                      />
                    ) : (
                      <UserGroupIcon className={classNames(textColor)} />
                    )}
                  </div>
                  <span
                    className={`block text-md pl-1 ${textColor} overflow-auto`}
                  >
                    {currentToken?.name}
                  </span>
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none ">
                  <ChevronDownIcon
                    aria-hidden="true"
                    className={`w-5 h-5 ${textColor}`}
                  />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
              >
                {/* the height for this element is managed by the list of elements max-height property */}
                <Listbox.Options className="absolute z-50 mt-2 overflow-hidden -translate-x-1/2 bg-white shadow-lg drop-shadow-2xl w-72 left-1/2 rounded-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {data?.findAllCommunityTokens.length == 0 ? (
                    <>
                      <div className="flex flex-col items-center justify-center pt-2 space-y-2">
                        <div className="flex flex-row items-center justify-center space-x-2">
                          <div className="font-semibold text-gray-400">
                            No tokens exist
                          </div>
                          <EmojiSadIcon className="w-5 h-5 text-gray-400" />
                        </div>
                        <div className="flex flex-row items-center justify-center pb-2">
                          <div className="flex flex-row items-center justify-center space-x-2 font-semibold text-gray-400">
                            <Link
                              href={{
                                pathname: `/${communitySlug}/admin/tokens`,
                              }}
                              passHref
                            >
                              <div className="hover:cursor-pointer">
                                Create one here
                              </div>
                            </Link>
                            <Link
                              href={{
                                pathname: `/${communitySlug}/admin/tokens`,
                              }}
                              passHref
                            >
                              <ArrowCircleRightIcon className="w-5 h-5 font-semibold text-gray-400 hover:cursor-pointer" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-row items-center px-3 py-2 space-x-1 border-b-2 border-slate-200">
                        <SearchIcon className="w-6 h-6 fill-slate-500" />
                        <input
                          onChange={(e) => {
                            setFilter(e.target.value);
                          }}
                          className="w-full px-2 py-1 leading-tight text-gray-700 bg-transparent appearance-none focus:outline-none"
                          placeholder="Find a token..."
                        />
                      </div>
                      <div className="overflow-scroll max-h-[35vh]">
                        {data?.findAllCommunityTokens ? (
                          data.findAllCommunityTokens
                            .filter((token) => filterTokens(token))
                            .map((token) => (
                              <CustomListBoxOption
                                key={token.communityId}
                                token={token}
                              />
                            ))
                        ) : (
                          <div className="flex flex-row py-8 space-x-4">
                            <div className="ml-4 font-semibold text-white text-md">
                              No Tokens Found
                            </div>
                            <EmojiSadIcon className="w-5 h-5 text-white" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </ClientOnly>
  );
};
