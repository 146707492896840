/* eslint-disable @next/next/no-img-element */
import { ChartBarIcon, TrashIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { ListItem } from "../../../interfaces";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";

const iconStyle = "bg-blue-500 fill-white rounded-full p-2";

export const ModeratorSidebarList: ListItem[] = [
  {
    name: "Main",
    slug: "",
    description: "",
    icon: <></>,
  },
  {
    name: "Analytics",
    slug: "analytics",
    description: "Monitor and analyze Lobby usage and patterns",
    icon: (
      <div className={iconStyle}>
        <ChartBarIcon color="white" />
      </div>
    ),
  },
  {
    name: "Wallet",
    slug: "wallet",
    description: "Check Lobby contributor wallet address and balance",
    icon: (
      <div className={iconStyle}>
        {/* breaks styling to use next image, keeping as img */}
        <img
          src="/assets/wallet-icon/wallet-icon.svg"
          alt="Wallet Icon"
          className="p-1"
        />
      </div>
    ),
  },
  {
    name: "Bulk hide",
    slug: "bulk-hide",
    description: "Hide many reported documents at once",
    icon: (
      <div className={iconStyle}>
        <TrashIcon color="white" />
      </div>
    ),
  },
];

export const ModeratorSidebar = () => {
  const { communitySlug, moderatorName } = useLobbyRouter();
  return (
    <div className="flex flex-col mt-4 overflow-hidden border-2 w-52 rounded-3xl">
      {ModeratorSidebarList.map((item) => {
        return (
          <Link
            key={item.name}
            href={`/${communitySlug}/moderator/${item.slug}`}
          >
            <a
              className={`${
                item.name === moderatorName ||
                (item.name === "Main" && moderatorName === "Moderator")
                  ? "bg-slate-100"
                  : ""
              } text-blue-500 hover:bg-gray-100 active:bg-gray-200 hover:cursor-pointer py-3 px-5`}
            >
              {item.name}
            </a>
          </Link>
        );
      })}
      <div className="h-56 " />
    </div>
  );
};
