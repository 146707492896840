import { useLazyQuery } from "@apollo/client";
import {
  Action,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarSearch,
  useRegisterActions,
} from "kbar";
import { useEffect } from "react";
import { animatorStyle, RenderResults, searchStyle } from "..";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import { usePreferencesContext } from "../../../contexts/PreferencesContext";
import { Community, QueryCommunityArgs } from "../../../generated/graphql";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { CommunityQuery } from "../../../lib/queries/Communities";

export default function CommandK() {
  // Needed to move kbarActions in here because we need to use the
  // useRegisterActions hook but it can only be used within the
  // KBarProvider to rerender the commands since we do conditional
  // rendering on the commands with token
  const { setCurrentMode } = usePreferencesContext();
  const { query, push, locale, communitySlug, isCommunityIndexPage } =
    useLobbyRouter();
  const { token } = useAuthenticationContext();

  const [fetchCommunity, { loading, data: documentData }] = useLazyQuery<
    { community: Community },
    QueryCommunityArgs
  >(CommunityQuery, {
    variables: { communitySlug },
  });

  useEffect(() => {
    if (!communitySlug || communitySlug === "[community]") return;

    fetchCommunity({
      variables: { communitySlug },
    });
  }, [communitySlug]);

  let kbarActions: Action[] = [];

  if (isCommunityIndexPage) {
    if (token && documentData?.community?.isWriter)
      kbarActions = [
        {
          id: "create",
          name: "Create Document in Community",
          shortcut: ["c"],
          keywords: "writing words create document",
          perform: () =>
            push(`${communitySlug}/new`, `${communitySlug}/new`, {
              locale: locale,
            }),
        },
      ];
    else kbarActions = [];
  } else {
    kbarActions = [
      {
        id: "home",
        name: "Go Community Homepage",
        shortcut: ["g", "h"],
        keywords: "go home",
        perform: () => push(`/${query.community}`),
      },
      {
        id: "view",
        name: "View Documents",
        shortcut: ["v"],
        keywords: "view document",
        perform: () => {
          setCurrentMode("");
          push(`/${query.community}/${query.document}`);
        },
      },

      {
        id: "revisions",
        name: "View Revisions for Document",
        shortcut: ["r"],
        keywords: "revisions document",
        perform: () => {
          setCurrentMode("revisions");
          push(`/${query.community}/${query.document}/revisions`);
        },
      },
    ];
    if (token && documentData?.community?.isWriter) {
      kbarActions.push(
        ...[
          {
            id: "edit",
            name: "Edit Current Document",
            shortcut: ["e"],
            keywords: "editing edit document",
            perform: () => {
              setCurrentMode("edit");
              push(`/${query.community}/${query.document}/edit`);
            },
          },
          {
            id: "create",
            name: "Create Document",
            shortcut: ["c"],
            keywords: "create document",
            perform: () => push(`/${query.community}/new`),
          },
        ]
      );
      if (!documentData?.community?.prefs?.isUserWorkspace) {
        kbarActions.push({
          id: "translate",
          name: "View Translations for Document",
          shortcut: ["t"],
          keywords: "translations translate document",
          perform: () => {
            setCurrentMode("translate");
            push(`/${query.community}/${query.document}/translate`);
          },
        });
      }
    }
  }

  useRegisterActions(kbarActions, [token, loading, documentData?.community]);

  return (
    <KBarPortal>
      <KBarPositioner>
        <KBarAnimator style={animatorStyle}>
          <KBarSearch style={searchStyle} />
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
}
