import { useKBar, VisualState } from "kbar";
import React, { useEffect, useState } from "react";

{
  /* <button className="relative py-2 pl-3 pr-10 text-left transform -translate-y-0.5 bg-gray-50 hover:bg-gray-100 cursor-pointer border border-gray-200 rounded-lg w-52 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
<span className="flex items-center truncate">
  <div className="w-3 h-3 text-gray-700">{currentMode.icon}</div>
  <span className="pl-2 text-xs font-medium text-gray-800">
    {currentMode.name}
  </span>
</span>
</button> */
}

export default function CommandKCue() {
  const { query } = useKBar((state) => ({
    visualState: state.visualState,
    showing: state.visualState !== VisualState.hidden,
  }));

  const [cueText, setCueText] = useState<string>("");
  const showCommandK = () => {
    query.setVisualState((vs) => {
      if (vs === VisualState.showing || vs === VisualState.animatingIn) {
        return vs;
      }
      return VisualState.animatingIn;
    });
  };
  useEffect(() => {
    const commandCueSymbol = navigator.userAgent.indexOf("Mac") ? "⌘" : "^";
    setCueText(commandCueSymbol);
  }, []);
  return (
    <button
      onClick={showCommandK}
      className="relative px-3 py-1 text-left border border-gray-200 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm"
    >
      <span className="flex items-center truncate">
        <div className="flex items-center justify-center w-4 h-6 text-lg">⚡️</div>
        <span className="pl-2 text-sm font-medium text-gray-800">
          {cueText}K
        </span>
      </span>
    </button>
  );
}
