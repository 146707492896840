import { debounce } from "lodash";
import React, { RefObject } from "react";

interface Props {
  func: () => void;
  ref: RefObject<HTMLDivElement>;
  delay?: number;
}

export const useFunctionOnBottomOfElement = ({
  ref,
  func,
  delay = 150,
}: Props) => {
  const debouncedFunctionAtEndOfElement = debounce(() => {
    if (!ref.current) return;

    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    if (scrollTop + clientHeight === scrollHeight) {
      func();
    }
  }, delay);

  return { debouncedFunctionAtEndOfElement };
};
