import { MouseEventHandler } from "react";
import { ConnectBase } from "./ConnectBase";
import coinbaseWalletIcon from "./coinbase-wallet.png";
import { Connector } from "wagmi";

export function ConnectWalletLink({
  onClick,
  connector,
}: {
  connector: Connector;
  onClick: MouseEventHandler;
}) {
  return (
    <ConnectBase
      name={connector.name}
      imageUrl={coinbaseWalletIcon}
      onClick={onClick}
    />
  );
}
