import { useLazyQuery } from "@apollo/client";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { HTMLAttributes, useEffect } from "react";
import {
  Community as CommunityType,
  QueryCommunityArgs,
} from "../../../generated/graphql";
import { defaultLandingBackgroundImage } from "../../../lib/constants";
import { CommunityQuery } from "../../../lib/queries/Communities";
import { updateContentWithPinataURL } from "../../../lib/util/util";
import { CommunitySelector, Logo, LogoWhite, Tabs } from "../../atoms";
import { Profile } from "../../atoms/Profile/Profile";
import { SearchHeader } from "../../molecules";
import styles from "./Header.module.css";

type HeaderThemes = "default" | "white" | "transparent";

export const Header = ({
  style = {},
}: {
  style: HTMLAttributes<any>["style"];
}) => {
  const { pathname, query } = useRouter();
  const headerBg = useHeaderBgUrl();
  let theme: HeaderThemes = "default";

  if (pathname === "/") theme = "white";
  if (pathname === "/[community]") theme = "transparent";

  // let parentDivStyle = "";
  // let dashboardTextStyle = "";
  // let borderlineStyle = "";
  // let communitySelectorBackgroundColorStyle = "";
  // let communitySelectorHoverBackgroundColorStyle = "";
  // let communitySelectorTextColorStyle = "";
  // let showSearchBar = true;
  // let customStyle = {};

  const {
    parentDivStyle,
    dashboardTextStyle,
    borderlineStyle,
    communitySelectorBackgroundColorStyle,
    communitySelectorHoverBackgroundColorStyle,
    communitySelectorTextColorStyle,
    showSearchBar,
    customStyle,
  } = getThemeVariables(theme, headerBg);

  return (
    <div
      style={{ ...customStyle, ...style }}
      className={`flex flex-col flex-grow ${parentDivStyle} pt-1`}
    >
      <div className={`flex flex-row relative justify-between p-2`}>
        <a
          style={{
            marginLeft: style?.marginLeft
              ? typeof style.marginLeft == "string"
                ? style.marginLeft.startsWith("-")
                  ? style.marginLeft.slice(1)
                  : "-" + style.marginLeft
                : 0 - style.marginLeft
              : "",
          }}
          className={classNames(styles.skipToContent, "peer")}
          href="#content"
        >
          Skip to content
        </a>
        <Link href="/">
          <a className="flex items-center hover:cursor-pointer peer-focus:translate-x-44">
            {theme === "white" ? <Logo /> : <LogoWhite />}
            <div
              className={`ml-3 border-l-0 border h-5 ${borderlineStyle} hidden lg:block`}
            />
            <p
              className={`ml-3 text-sm font-semibold ${dashboardTextStyle} hidden lg:block`}
            >
              Home
            </p>
          </a>
        </Link>
        <div className="absolute z-50 flex items-center justify-center -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <CommunitySelector
            backgroundColor={communitySelectorBackgroundColorStyle}
            hoverBackgroundColor={communitySelectorHoverBackgroundColorStyle}
            textColor={communitySelectorTextColorStyle}
          />
        </div>
        <div className="grid items-center grid-flow-col-dense space-x-1 place-items-start">
          <div>{showSearchBar && <SearchHeader />}</div>
          <div>
            <Profile />
          </div>
        </div>
      </div>
      {query.community && <Tabs pill={theme == "transparent"} />}
    </div>
  );
};

function getThemeVariables(
  theme: string,
  headerBg: string
): {
  parentDivStyle?: string;
  customStyle?: {};
  dashboardTextStyle: string;
  borderlineStyle: string;
  communitySelectorBackgroundColorStyle: string;
  communitySelectorHoverBackgroundColorStyle: string;
  communitySelectorTextColorStyle: string;
  showSearchBar: boolean;
} {
  switch (theme) {
    case "transparent":
      return {
        parentDivStyle: "bg-black/30",
        dashboardTextStyle: "text-white",
        borderlineStyle: "border-white",
        communitySelectorBackgroundColorStyle: "bg-white/10",
        communitySelectorHoverBackgroundColorStyle: "hover:bg-white/30",
        communitySelectorTextColorStyle: "text-white",
        showSearchBar: true,
      };

    case "white":
      return {
        parentDivStyle: "",
        dashboardTextStyle: "text-black",
        borderlineStyle: "border-black",
        communitySelectorBackgroundColorStyle: "bg-slate-200",
        communitySelectorHoverBackgroundColorStyle: "hover:bg-slate-300",
        communitySelectorTextColorStyle: "",
        showSearchBar: false,
      };

    default:
      return {
        customStyle: {
          background: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${headerBg}) no-repeat center center / 100%`,
          backgroundSize: "cover",
        },
        parentDivStyle: "min-w-screen",
        dashboardTextStyle: "text-white",
        borderlineStyle: "border-white",
        communitySelectorBackgroundColorStyle: "bg-white/10",
        communitySelectorHoverBackgroundColorStyle: "hover:bg-white/30",
        communitySelectorTextColorStyle: "text-white",
        showSearchBar: true,
      };
  }
}

function useHeaderBgUrl(): string {
  const { query } = useRouter();

  const communitySlug = (query.community as string) || "";

  const [fetchCommunity, { loading, data: communityData }] = useLazyQuery<
    { community: CommunityType },
    QueryCommunityArgs
  >(CommunityQuery, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!communitySlug || communitySlug === "[community]") return;

    fetchCommunity({
      variables: { communitySlug },
    });
  }, [communitySlug]);

  return communityData?.community?.landingPrefs.backgroundImageUrl
    ? updateContentWithPinataURL(
        communityData?.community?.landingPrefs.backgroundImageUrl
      )
    : defaultLandingBackgroundImage;
}
