import Link from "next/link";
import { ListItem } from "../../../interfaces";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";

interface PageCardProps {
  route: string;
  item: ListItem;
}

export const PageCard = ({ route, item }: PageCardProps) => {
  const { communitySlug } = useLobbyRouter();

  return (
    <Link href={`/${communitySlug}/${route}/${item.slug}`}>
      <a className="my-2 mr-4 ">
        <div className="flex flex-col p-3 space-y-2 border-2 hover:bg-gray-100 active:bg-gray-200 rounded-xl w-60 hover:cursor-pointer">
          <div className="w-12 h-12 rounded-full">{item.icon}</div>
          <div>
            <div className="font-bold">{item.name}</div>
            <div className=" text-slate-500">{item.description}</div>
          </div>
        </div>
      </a>
    </Link>
  );
};
