import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { User } from "../../../generated/graphql";
import { ENSNameAndAvatar } from "../../atoms";

interface ContributorModalProps {
  contributors: User[];
}
export function ContributorModal({ contributors }: ContributorModalProps) {
  const [isOpen, setOpen] = useState(false);

  function openModal() {
    setOpen(true);
  }
  function closeModal() {
    setOpen(false);
  }

  const contributorsLength = contributors?.length ?? 0;

  return (
    <>
      <div
        className="flex-col mb-2 hover:cursor-pointer hover:bg-gray-100"
        onClick={openModal}
      >
        <div className="font-semibold text-gray-400">CONTRIBUTORS</div>
        <span className="flex overflow-x-hidden">
          {contributors.map((contributor, i) => {
            //this is done to prevent contributor avatars from overflowing
            if (i > 3) {
              return <></>;
            }
            return (
              <ENSNameAndAvatar
                address={contributor.address}
                key={i}
                onlyAvatar={true}
              />
            );
          })}
          {contributorsLength > 3 ? `and ${contributorsLength - 4} others` : ""}
        </span>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center bg-slate-500 bg-opacity-40">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex items-center justify-between mb-6">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-semibold leading-6 text-gray-900"
                  >
                    Contributors
                  </Dialog.Title>
                  <button
                    onClick={closeModal}
                    className="p-2 rounded-full hover:bg-gray-100 active:bg-gray-200"
                  >
                    <XIcon className="w-5 h-5 text-gray-600" />
                  </button>
                </div>
                <div>
                  <div className="inline-block w-full max-w-lg overflow-hidden text-left align-middle transition-all transform md:max-w-2xl">
                    <div className="flex-col">
                      {contributors.map((contributor, i) => {
                        //this is done to prevent contributor avatars from overflowing
                        return (
                          <div className="mb-2" key={i}>
                            <ENSNameAndAvatar address={contributor.address} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
