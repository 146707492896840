interface SupportedLanguage {
  id: number;
  name: string;
  nameNative?: string;
  locale: string;
  flagImageLink: string;
}
const supportedLanguages: SupportedLanguage[] = [
  {
    id: 1,
    name: "English",
    locale: "en-US",
    flagImageLink: "/assets/languages/en.svg",
  },
  {
    id: 2,
    name: "Mandarin",
    nameNative: "普通话",
    locale: "zh",
    flagImageLink: "/assets/languages/zh.svg",
  },
  {
    id: 3,
    name: "Hindi",
    nameNative: "हिन्दी",
    locale: "hi",
    flagImageLink: "/assets/languages/hi.svg",
  },
  {
    id: 4,
    name: "Spanish",
    nameNative: "Español",
    locale: "es",
    flagImageLink: "/assets/languages/es.svg",
  },
  {
    id: 5,
    name: "French",
    nameNative: "Français",
    locale: "fr",
    flagImageLink: "/assets/languages/fr.svg",
  },
  {
    id: 6,
    name: "Arabic",
    nameNative: "عربي",
    locale: "ar",
    flagImageLink: "/assets/languages/ar.svg",
  },
  {
    id: 7,
    name: "Japanese",
    nameNative: "日本",
    locale: "ja",
    flagImageLink: "/assets/languages/ja.svg",
  },
  {
    id: 8,
    name: "Korean",
    nameNative: "한국어",
    locale: "ko",
    flagImageLink: "/assets/languages/ko.svg",
  },
  {
    id: 9,
    name: "Russian",
    nameNative: "Русский",
    locale: "ru",
    flagImageLink: "/assets/languages/ru.png",
  },
  {
    id: 10,
    name: "Portuguese",
    nameNative: "Português",
    locale: "pt",
    flagImageLink: "/assets/languages/pt.png",
  },
  {
    id: 11,
    name: "Indonesian",
    nameNative: "bahasa Indonesia",
    locale: "id",
    flagImageLink: "/assets/languages/id.png",
  },
];
export { type SupportedLanguage, supportedLanguages };
