import React, { useEffect } from "react";
import { SidebarQueryInterface } from "../../components/layout/DocumentLayout/DocumentLayout";
import { ReducedFolder } from "../../interfaces";

export const useFolderRoots = ({
  documentListData,
}: {
  documentListData: SidebarQueryInterface | undefined;
}) => {
  const [folderRoots, setRoots] = React.useState<ReducedFolder[]>([]);

  useEffect(() => {
    if (documentListData?.folders) {
      const folders: ReducedFolder[] = documentListData.folders.folders.map(
        (folder) => {
          return {
            id: folder.id,
            name: folder.name,
            documents: folder.documents,
            translations: folder.translations,
            parentFolder: folder.parentFolder,
            subFolders: [] as ReducedFolder[],
          };
        }
      );

      const idMapping: Record<string, number> = folders.reduce(
        (prev, curr, i) => {
          return {
            ...prev,
            [curr.name]: i,
          };
        },
        {}
      );

      let roots: ReducedFolder[] = [];
      folders.forEach((el) => {
        // Handle the root element
        if (el.parentFolder === null) {
          roots.push(el);
          return;
        }

        // Use our mapping to locate the parent element in our data array
        const parentFolderIndex = idMapping[el.parentFolder?.name ?? 0];
        const parentEl = folders[parentFolderIndex];
        // Add our current el to its parent's `children` array
        parentEl.subFolders = [...(parentEl.subFolders || []), el];
      });

      setRoots(roots);
    }
  }, [documentListData?.folders]);

  return { folderRoots };
};
