import React, { useCallback, useRef } from "react";
import useKeyboardShortcut from "../../../lib/hooks/KeyboardShortcut/useKeyboardShortcut";

interface SearchBarProps {
  className: string;
  setValue: (value: string) => void;
}
export const SearchBar = ({ className, setValue }: SearchBarProps) => {
  const searchRef = useRef<any>();
  const handleKeyboardShortcut = useCallback(
    (keys) => {
      searchRef.current.focus();
    },
    [searchRef]
  );
  useKeyboardShortcut(["/"], handleKeyboardShortcut);

  return (
    <input
      type="text"
      className={className}
      placeholder="Press '/' to search.."
      onChange={(e) => {
        setValue(e.currentTarget.value);
      }}
      ref={searchRef}
      onFocus={(event: any) => {
        event.stopPropagation();
      }}
    />
  );
};
