import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftIcon, XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { Fragment } from "react";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { getArticleStorageKey } from "../../../lib/util/util.tiptap";
import { Button } from "../../atoms";

interface CommitMessageDialogProps {
  onCommitMessageChange: (e: string) => void;
  onSave: () => void;
  onDiscard: () => void;
  buttonLoading: boolean;
  newChangesMade: boolean;
  isCommitModalOpen: boolean;
  setCommitModalOpen: (isOpen: boolean) => void;
  isDiscardModalOpen: boolean;
  setDiscardModalOpen: (isOpen: boolean) => void;
}

export function CommitMessageDialog({
  onCommitMessageChange,
  onSave,
  onDiscard,
  buttonLoading,
  newChangesMade,
  isCommitModalOpen,
  setCommitModalOpen,
  isDiscardModalOpen,
  setDiscardModalOpen,
}: CommitMessageDialogProps) {
  const { documentSlug, communitySlug, locale } = useLobbyRouter();

  function closeCommitModal() {
    setCommitModalOpen(false);
  }

  function openCommitModal() {
    setCommitModalOpen(true);
  }

  function closeDiscardModal() {
    setDiscardModalOpen(false);
  }

  function openDiscardModal() {
    setDiscardModalOpen(true);
  }
  function discardEditorChanges() {
    sessionStorage.removeItem(
      getArticleStorageKey(locale, communitySlug, documentSlug)
    );
    onDiscard();
    closeDiscardModal();
  }

  //discard button is disabled if no new changes are made; thus, discard button true if new changes made is false
  const isDiscardButtonDisabled = !newChangesMade;

  return (
    <>
      <div className="flex items-center justify-between mt-2 mb-5">
        <button
          onClick={openDiscardModal}
          className={classNames(
            isDiscardButtonDisabled
              ? "text-gray-300 cursor-not-allowed"
              : "text-gray-500",
            "flex items-center text-sm font-medium"
          )}
          disabled={isDiscardButtonDisabled}
        >
          <ArrowLeftIcon className="w-3 h-3 mr-1" /> Don&apos;t save
        </button>
        <button
          onClick={openCommitModal}
          className={classNames(
            isDiscardButtonDisabled
              ? "text-gray-300 cursor-not-allowed bg-gray-400"
              : "text-white bg-blue-600 ",
            "px-3 py-1 text-sm font-medium rounded-md hover:opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none"
          )}
          disabled={isDiscardButtonDisabled}
        >
          Save
        </button>
      </div>

      <Transition appear show={isCommitModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[9999] overflow-y-auto"
          onClose={closeCommitModal}
        >
          <div className="min-h-screen px-4 text-center bg-slate-500 bg-opacity-40">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex items-center justify-between mb-6">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-semibold leading-6 text-gray-900"
                  >
                    Save Changes...
                  </Dialog.Title>
                  <button
                    onClick={closeCommitModal}
                    className="p-2 rounded-full hover:bg-gray-100 active:bg-gray-200"
                  >
                    <XIcon className="w-5 h-5 text-gray-600" />
                  </button>
                </div>
                <div>
                  <div className="inline-block w-full max-w-lg overflow-hidden text-left align-middle transition-all transform md:max-w-2xl">
                    <div className="flex-col">
                      <div className="mb-4">
                        Describe your changes to save the document
                      </div>
                      <textarea
                        className="w-full p-1 border-2 rounded-md"
                        rows={8}
                        placeholder="Added/Changed/Removed..."
                        onChange={(e) => {
                          onCommitMessageChange(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between mt-4">
                  <button
                    className={classNames(
                      isDiscardButtonDisabled
                        ? "text-gray-300"
                        : "text-red-700",
                      "font-medium"
                    )}
                    onClick={() => {
                      closeCommitModal();
                      openDiscardModal();
                    }}
                    disabled={isDiscardButtonDisabled}
                  >
                    Discard changes
                  </button>
                  <div>
                    <button
                      className="mr-3 text-gray-400"
                      onClick={closeCommitModal}
                    >
                      Cancel
                    </button>
                    <Button
                      onClick={onSave}
                      text={"Save"}
                      loading={buttonLoading}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/*Second transition for the discard changes dialog*/}
      <Transition appear show={isDiscardModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[9999] overflow-y-auto"
          onClose={closeDiscardModal}
        >
          <div className="min-h-screen px-4 text-center bg-slate-500 bg-opacity-40">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex items-center justify-between mb-6">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-semibold leading-6 text-gray-900"
                  >
                    Are you sure?
                  </Dialog.Title>
                  <button
                    onClick={closeDiscardModal}
                    className="p-2 rounded-full hover:bg-gray-100 active:bg-gray-200"
                  >
                    <XIcon className="w-5 h-5 text-gray-600" />
                  </button>
                </div>
                <div>
                  <div className="inline-block w-full max-w-lg overflow-hidden text-left align-middle transition-all transform md:max-w-2xl">
                    <div className="flex-col">
                      <div className="mb-4">
                        You&apos;re about to permanently discard your unsaved
                        changes.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end mt-4">
                  <button
                    className="mr-3 text-gray-400"
                    onClick={closeDiscardModal}
                  >
                    Cancel
                  </button>
                  <button
                    className={classNames(
                      isDiscardButtonDisabled ? "bg-gray-300" : "bg-red-500",
                      "font-medium text-white rounded-lg px-4 py-1"
                    )}
                    onClick={discardEditorChanges}
                    disabled={isDiscardButtonDisabled}
                  >
                    Discard
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
