import classNames from "classnames";
import React from "react";

interface SpinnerProps {
  color?: string;
  size?: string;
  className?: string;
}

export const Spinner = ({
  color = "text-slate-600",
  size = "md",
  className = "",
}: SpinnerProps) => {
  return (
    <div className={classNames(`flex items-center justify-center`, className)}>
      <div
        className={classNames(
          `${color} spinner-border animate-spin inline-block border-4 rounded-full`,
          size === "sm" && " w-6 h-6",
          size === "md" && " w-8 h-8",
          size === "lg" && " w-12 h-12"
        )}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
