import { gql } from "@apollo/client";

export const DocumentsBySlugQuery = gql`
  query Documents($communitySlug: String!, $pagination: PaginationArgs) {
    documents(communitySlug: $communitySlug, pagination: $pagination) {
      documents {
        documentId
        name
        slug
        tags
      }
      hasMore
    }
  }
`;

export const DocumentRolesQuery = gql`
  query RequiredDocumentRoles($communitySlug: String!, $slug: String!) {
    requiredDocumentRoles(communitySlug: $communitySlug, slug: $slug) {
      id
      name
      threshold
      color
      token {
        id
        name
        imageUrl
      }
    }
  }
`;

export const DocumentBySlugQuery = gql`
  query Document($communitySlug: String!, $slug: String!) {
    documentBySlug(communitySlug: $communitySlug, slug: $slug) {
      documentId
      name
      slug
      hidden
      tags
      communityId
      createdAt
      folder {
        name
      }
      translations {
        translation {
          id
          language
        }
        latestRevision {
          revision {
            id
            documentName
            content
            commitMessage
            userId
            documentTranslationId
            createdAt
          }
          isReportedByUser
        }
      }
      contributors {
        userId
        name
        address
      }
    }
  }
`;

export const DocumentByIdQuery = gql`
  query DocumentById($documentId: String!) {
    documentById(documentId: $documentId) {
      communityId
      documentId
      slug
      community {
        communityId
        slug
      }
    }
  }
`;

export const DocumentNoContributorsBySlugQuery = gql`
  query Document($communitySlug: String!, $slug: String!) {
    documentBySlug(communitySlug: $communitySlug, slug: $slug) {
      documentId
      name
      slug
      hidden
      communityId
      tags
      folder {
        name
      }
      translations {
        translation {
          id
          language
        }
        latestRevision {
          revision {
            id
            content
            commitMessage
            userId
            documentTranslationId
            createdAt
          }
          isReportedByUser
        }
      }
    }
  }
`;

export const ReportedDocumentsQuery = gql`
  query ReportedDocuments($communitySlug: String!) {
    reportedDocuments(communitySlug: $communitySlug) {
      documentId
      name
      slug
      hidden
      tags
      reports
    }
  }
`;
