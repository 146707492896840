import mixpanel from "mixpanel-browser";
import React, { useCallback } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import useLobbyRouter from "../useLobbyRouter";

export const useMixpanelCommunityEvent = () => {
  const { user, token } = useAuthenticationContext();
  const { communitySlug, locale } = useLobbyRouter();

  const mixPanelCreateCommunityEvent = useCallback(
    (
      communityName: string,
      communitySlug: string,
      communityIconURL: string
    ) => {
      mixpanel.track("Community Create", {
        communityName,
        communitySlug,
        communityIconURL,
        locale,
        // user is set by checking wallet, so see if they are logged in
        // then send the request with the userAddress (not everyone will be logged
        // in, but most of the time we will have the user)
        userAddress: token ? user?.address : null,
      });
    },
    [locale, user, token]
  );

  const mixPanelFolderCreateEvent = useCallback(
    (name: string, parentFolderName: string, translateLocale: string) => {
      mixpanel.track("Folder Create", {
        communitySlug,
        name,
        parentFolderName,
        translateLocale,
        authorAddress: token ? user?.address : null,
      });
    },
    [mixpanel, communitySlug, locale, user, token]
  );

  return { mixPanelCreateCommunityEvent, mixPanelFolderCreateEvent };
};
