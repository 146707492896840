import { useLazyQuery } from "@apollo/client";
import { PlusSmIcon } from "@heroicons/react/outline";
import { MenuIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import Link from "next/link";
import { useEffect } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import { usePreferencesContext } from "../../../contexts/PreferencesContext";
import { Community, QueryCommunityArgs } from "../../../generated/graphql";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { CommunityQuery } from "../../../lib/queries/Communities";
import { ToolTip } from "../ToolTip/ToolTip";

export const Tabs = ({ pill = false }: { pill?: boolean }) => {
  const { communitySlug } = useLobbyRouter();
  const { isSidebarOpen, setIsSidebarOpen } = usePreferencesContext();
  const { user, token } = useAuthenticationContext();

  const [fetchCommunity, { data: communityData }] = useLazyQuery<
    { community: Community },
    QueryCommunityArgs
  >(CommunityQuery, {
    variables: { communitySlug },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (communitySlug !== "[community]" && communitySlug !== "my-workspace")
      fetchCommunity();
  }, [communitySlug]);

  return (
    <div className="z-10 flex flex-wrap items-center px-2 pt-8 space-x-2 lg:flex lg:flex-row md:flex md:flex-row lg:pt-1">
      <button
        className={classNames(
          pill ? "rounded-md" : "rounded-t-md",
          "p-1 cursor-pointer hover:bg-white hover:bg-opacity-25 "
        )}
      >
        <MenuIcon
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
          }}
          className="w-5 h-5 fill-white"
        />
      </button>
      <Link href={`/${communitySlug}`}>
        <a
          className={`flex flex-row items-center justify-center space-x-1 px-3 py-1 text-sm font-semibold bg-white rounded-t-md hover:cursor-pointer ${
            pill ? "rounded-md" : "rounded-t-md"
          }`}
        >
          <div>{communityData?.community?.name}</div> <div>Home</div>
        </a>
      </Link>
      {token && (communityData?.community?.isModerator || user?.isSuperAdmin) && (
        <Link href={`/${communitySlug}/moderator`}>
          <a
            className={`px-3 py-1 text-sm font-semibold text-yellow-800 bg-yellow-300  hover:cursor-pointer ${
              pill ? "rounded-md" : "rounded-t-md"
            }`}
          >
            Moderator
          </a>
        </Link>
      )}
      {token && (communityData?.community?.isAdmin || user?.isSuperAdmin) && (
        <Link href={`/${communitySlug}/admin`}>
          <a
            className={`px-3 py-1 text-sm font-semibold text-red-800 bg-red-300  hover:cursor-pointer ${
              pill ? "rounded-md" : "rounded-t-md"
            }`}
          >
            Admin
          </a>
        </Link>
      )}
      {token && (communityData?.community?.isWriter || user?.isSuperAdmin) && (
        <ToolTip content="Create" shortcut={["C"]} position="bottom">
          <Link href={`/${communitySlug}/new`}>
            <a className="grid items-center grid-flow-col space-x-1 hover:cursor-pointer">
              <PlusSmIcon className="w-4 h-4 text-white rounded-md bg-slate-300/50" />
              <h1 className="text-sm font-medium text-white">Create article</h1>
            </a>
          </Link>
        </ToolTip>
      )}
    </div>
  );
};
