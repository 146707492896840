import mixpanel from "mixpanel-browser";
import { useCallback, useState } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import useLobbyRouter from "../useLobbyRouter";

type MixPanelDocumentEvent = "Document create" | "Document edit";

export const useMixpanelDocumentEvent = () => {
  const { communitySlug, locale, documentSlug } = useLobbyRouter();
  const { user, token } = useAuthenticationContext();

  const [trackedCommunity, setTrackedCommunity] = useState("");
  const [trackedDocument, setTrackedDocument] = useState("");

  const mixPanelDocumentEvent = useCallback(
    (event: MixPanelDocumentEvent, documentSlug: string) => {
      mixpanel.track(event, {
        communitySlug,
        documentSlug,
        locale,
        // should never be null if the request for creating the document goes through
        authorAddress: token ? user?.address : null,
      });
    },
    [mixpanel, communitySlug, locale, user, token]
  );

  const mixPanelDocumentTranslateEvent = useCallback(
    (documentSlug: string, translateLocale: string) => {
      mixpanel.track("Document translate", {
        communitySlug,
        documentSlug,
        locale,
        translateLocale,
        // should never be null if the request for creating the document goes through
        authorAddress: token ? user?.address : null,
      });
    },
    [mixpanel, communitySlug, locale, user, token]
  );

  const mixPanelDocumentScrollEvent = () => {
    if (
      !communitySlug ||
      communitySlug === "[community]" ||
      documentSlug === "[documentSlug]" ||
      // prevents double tracking scrolls
      (trackedCommunity === communitySlug &&
        trackedDocument === documentSlug) ||
      // in the auth context it takes a second to fetch
      // user when there is a token
      (!user && token)
    )
      return;

    mixpanel.track("Document scroll", {
      communitySlug,
      documentSlug,
      locale,
      // should never be null if the request for creating the document goes through
      authorAddress: token ? user?.address : null,
    });
    setTrackedCommunity(communitySlug);
    setTrackedDocument(documentSlug);
  };

  return {
    mixPanelDocumentEvent,
    mixPanelDocumentTranslateEvent,
    mixPanelDocumentScrollEvent,
  };
};
