import { useLazyQuery, useMutation } from "@apollo/client";
import { FlagIcon as OutlineFlagIcon } from "@heroicons/react/outline";
import { FlagIcon as SolidFlagIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import { usePreferencesContext } from "../../../contexts/PreferencesContext";
import {
  Community,
  Maybe,
  MutationCreateReportArgs,
  MutationDeleteReportArgs,
  QueryCommunityArgs,
  Report,
  RevisionWithReport,
} from "../../../generated/graphql";
import { ListItem } from "../../../interfaces";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import {
  CreateReportMutation,
  DeleteReportMutation,
} from "../../../lib/mutations/Reports";
import { CommunityQuery } from "../../../lib/queries/Communities";
import { BreadCrumbs, LanguageSelector, ModeSelector } from "../../atoms";
import { modes } from "../../atoms/ModeSelector/ModeSelector";
import CommandKCue from "../CommandK/CommandKCue";

interface DocumentHeaderProps {
  documentName?: string;
  documentId?: string;
  revisionWithReport: Maybe<RevisionWithReport> | undefined;

  // hides the entire header
  hidden?: boolean;
}

export const DocumentHeader = ({
  documentName,
  documentId,
  revisionWithReport,
  hidden = false,
}: DocumentHeaderProps) => {
  const { setCurrentMode } = usePreferencesContext();

  const [showLanguageSelector, setShowLanguageSelector] = useState(true);
  const [isWriter, setIsWriter] = useState(false);

  const {
    query,
    asPath,
    pathname,
    push,
    communitySlug,
    documentSlug,
    isCommunityIndexPage,
  } = useLobbyRouter();
  const path = asPath.split("/");

  const [fetchCommunity, { loading, data: documentData }] = useLazyQuery<
    { community: Community },
    QueryCommunityArgs
  >(CommunityQuery, {
    variables: { communitySlug },
  });

  useEffect(() => {
    if (!communitySlug || communitySlug === "[community]") return;

    fetchCommunity({
      variables: { communitySlug },
    });
  }, [communitySlug]);

  var currentMode = modes[0];
  // Sets the mode selector (view, edit, translate, revisions)
  if (path.length === 3) {
    currentMode = modes[0];
    setCurrentMode(currentMode.slug);
  } else if (path[3]) {
    if (path[3] === "edit") {
      currentMode = modes[1];
      setCurrentMode(currentMode.slug);
    } else if (path[3] === "translate") {
      currentMode = modes[2];
      setCurrentMode(currentMode.slug);
    }
    // When revision ID is in the url, the path is actually
    // revisions?revisionId=ckzdg9el02832l3pjtph7sxqj
    else if (path[3].includes("revisions")) {
      currentMode = modes[3];
      setCurrentMode(currentMode.slug);
    }
  }

  const { token, user } = useAuthenticationContext();
  const [revision, setRevision] = useState(revisionWithReport?.revision);

  const [createReport, { data: createReportData, error: createReportError }] =
    useMutation<{ createReport: Report }, MutationCreateReportArgs>(
      CreateReportMutation,
      {
        variables: {
          revisionId: revision?.id ?? "",
          communitySlug,
        },
      }
    );
  const [deleteReport, { data: deleteReportData, error: deleteReportError }] =
    useMutation<{ deleteReport: Report }, MutationDeleteReportArgs>(
      DeleteReportMutation,
      {
        variables: {
          revisionId: revision?.id ?? "",
          communitySlug,
        },
      }
    );

  const [isReported, setIsReported] = useState(
    revisionWithReport?.isReportedByUser ?? false
  );

  useEffect(() => {
    documentData?.community && setIsWriter(documentData.community.isWriter);
  }, [documentData?.community, loading]);

  // Initial loading with revision, points revision and isReported states
  useEffect(() => {
    setRevision(revisionWithReport?.revision ?? undefined);
  }, [revisionWithReport]);

  // After sending create/delete mutations, waits for response then sets flag
  useEffect(() => {
    if (createReportData) setIsReported(true);
  }, [createReportData]);
  useEffect(() => {
    if (deleteReportData) setIsReported(false);
  }, [deleteReportData]);

  // In translate mode, make language selector dissappear
  useEffect(() => {
    if (currentMode === modes[2]) setShowLanguageSelector(false);
  }, [currentMode]);

  const onFlagClick = () => {
    if (isReported) {
      deleteReport().catch((error) => {
        if (error.message === "Revision has not been reported by this user.") {
          console.warn(error.message);
          setIsReported(false);
        } else throw error;
      });
    } else {
      createReport().catch((error) => {
        if (
          error.message === "Revision has already been reported by this user."
        ) {
          console.warn(error.message);
          setIsReported(true);
        } else throw error;
      });
    }
  };

  const setMode = (value: ListItem) => {
    setCurrentMode(value.slug);
    push(`/${query.community}/${query.document}/${value.slug}`);
  };

  if (hidden) {
    return null;
  }

  return (
    <>
      <div className="flex flex-row content-center justify-between w-screen px-4 py-2 border-b">
        <div className="flex flex-row items-center">
          <CommandKCue />
          <div className="w-2"></div>
          <div
            className={pathname === "/[community]/search" ? "invisible" : ""}
          >
            <ModeSelector
              currentMode={currentMode}
              isNewDocument={pathname === "/[community]/new"}
              isCommunityIndexPage={isCommunityIndexPage}
              setMode={setMode}
              isWriter={isWriter}
            />
          </div>
          {revisionWithReport &&
            isReported !== undefined &&
            token &&
            revision !== undefined &&
            communitySlug !== user?.userId && (
              <div
                className="p-2 ml-4 rounded-md hover:cursor-pointer hover:bg-gray-100"
                onClick={onFlagClick}
              >
                {isReported ? (
                  <SolidFlagIcon className="w-4 h-4" />
                ) : (
                  <OutlineFlagIcon className="w-4 h-4" />
                )}
              </div>
            )}
          <div>
            <BreadCrumbs documentName={documentName} documentId={documentId} />
          </div>
        </div>
        {showLanguageSelector && (
          <LanguageSelector
            showCircularView={true}
            setViewing={() => {}}
            setIsReported={setIsReported}
          />
        )}
      </div>
    </>
  );
};
