import mixpanel from "mixpanel-browser";
import { useCallback } from "react";

export const useMixpanelAuthEvent = () => {
  const mixPanelSignInEvent = useCallback(
    (address: string) => {
      mixpanel.track("Sign in", {
        userAddress: address,
      });
    },
    [mixpanel]
  );

  return { mixPanelSignInEvent };
};
