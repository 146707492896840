import { XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { useState } from "react";

interface BadgeProps {
  name: string;
  buttonOnClick?: () => void;
}

export const RoleBadge = ({ name, buttonOnClick }: BadgeProps) => {
  return (
    <span
      className={classNames(
        buttonOnClick && "flex group items-center hover:cursor-pointer",
        "px-2 m-1 font-light text-xs text-white bg-purple-500 rounded-md"
      )}
      onClick={buttonOnClick}
    >
      <span>{name}</span>
      {buttonOnClick && (
        <span>
          <XIcon className="w-3 h-3 ml-1 group-hover:bg-red-800 group-hover:rounded" />
        </span>
      )}
    </span>
  );
};
