import { gql } from "@apollo/client";

export const UpdateFolderData = gql`
  mutation UpdateFolderData(
    $folderName: String!
    $communitySlug: String!
    $newName: String
    $newDescription: String
    $language: String!
  ) {
    updateFolderData(
      folderName: $folderName
      communitySlug: $communitySlug
      newName: $newName
      newDescription: $newDescription
      language: $language
    ) {
      id
      name
      description
      communityId
      translations {
        id
        language
        name
        description
      }
    }
  }
`
export const DeleteFolderMutation = gql`
  mutation DeleteFolder($folderName: String!, $communitySlug: String!) {
    deleteFolder(folderName: $folderName, communitySlug: $communitySlug) {
      name
    }
  }
`;

export const UpdateParentFolderMutation = gql`
  mutation UpdateParentFolder(
    $parentFolderName: String!
    $folderName: String!
    $communitySlug: String!
  ) {
    updateParentFolder(
      parentFolderName: $parentFolderName
      folderName: $folderName
      communitySlug: $communitySlug
    ) {
      id
      name
    }
  }
`;

export const CreateFolderMutation = gql`
  mutation CreateFolder(
    $communitySlug: String!
    $name: String!
    $parentFolderName: String
    $language: String!
  ) {
    createFolder(
      communitySlug: $communitySlug
      name: $name
      parentFolderName: $parentFolderName
      language: $language
    ) {
      id
      name
      translations {
        id
        language
        name
        description
      }
    }
  }
`;

export const AddReadRoleToFolder = gql`
  mutation AddReadRoleToFolder(
    $roleId: String!
    $folderName: String!
    $communitySlug: String!
  ) {
    addReadRoleToFolder(
      roleId: $roleId
      folderName: $folderName
      communitySlug: $communitySlug
    ) {
      name
    }
  }
`;

export const AddWriteRoleToFolder = gql`
  mutation AddWriteRoleToFolder(
    $roleId: String!
    $folderName: String!
    $communitySlug: String!
  ) {
    addWriteRoleToFolder(
      roleId: $roleId
      folderName: $folderName
      communitySlug: $communitySlug
    ) {
      name
    }
  }
`;

export const AddModeratorRoleToFolder = gql`
  mutation AddModeratorRoleToFolder(
    $roleId: String!
    $folderName: String!
    $communitySlug: String!
  ) {
    addModeratorRoleToFolder(
      roleId: $roleId
      folderName: $folderName
      communitySlug: $communitySlug
    ) {
      name
    }
  }
`;

export const AddAdminRoleToFolder = gql`
  mutation AddAdminRoleToFolder(
    $roleId: String!
    $folderName: String!
    $communitySlug: String!
  ) {
    addAdminRoleToFolder(
      roleId: $roleId
      folderName: $folderName
      communitySlug: $communitySlug
    ) {
      name
    }
  }
`;

export const RemoveReadRoleFromFolder = gql`
  mutation RemoveReadRoleFromFolder(
    $roleId: String!
    $folderName: String!
    $communitySlug: String!
  ) {
    removeReadRoleFromFolder(
      roleId: $roleId
      folderName: $folderName
      communitySlug: $communitySlug
    ) {
      name
    }
  }
`;

export const RemoveWriteRoleFromFolder = gql`
  mutation RemoveWriteRoleFromFolder(
    $roleId: String!
    $folderName: String!
    $communitySlug: String!
  ) {
    removeWriteRoleFromFolder(
      roleId: $roleId
      folderName: $folderName
      communitySlug: $communitySlug
    ) {
      name
    }
  }
`;

export const RemoveModeratorRoleFromFolder = gql`
  mutation RemoveModeratorRoleFromFolder(
    $roleId: String!
    $folderName: String!
    $communitySlug: String!
  ) {
    removeModeratorRoleFromFolder(
      roleId: $roleId
      folderName: $folderName
      communitySlug: $communitySlug
    ) {
      name
    }
  }
`;

export const RemoveAdminRoleFromFolder = gql`
  mutation RemoveAdminRoleFromFolder(
    $roleId: String!
    $folderName: String!
    $communitySlug: String!
  ) {
    removeAdminRoleFromFolder(
      roleId: $roleId
      folderName: $folderName
      communitySlug: $communitySlug
    ) {
      name
    }
  }
`;
