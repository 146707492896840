import { ApolloProvider } from "@apollo/client";
import "degen/styles";
import { providers } from "ethers";
import "lobby-editor/dist/es/styles.css";
import type { AppProps } from "next/app";
import { SnackbarProvider } from "notistack";
import { createRef, RefObject, useEffect } from "react";
import { hotjar } from "react-hotjar";
import "react-tippy/dist/tippy.css";
import "remixicon/fonts/remixicon.css";
import {
  chain,
  Connector,
  defaultChains,
  InjectedConnector,
  Provider,
} from "wagmi";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { WalletLinkConnector } from "wagmi/connectors/walletLink";
import { AuthProvider } from "../contexts/AuthenticationContext";
import { PreferencesProvider } from "../contexts/PreferencesContext";
import client from "../lib/apollo/apollo-client";
import "../styles/globals.css";
import "lobby-editor/dist/es/styles.css";
// API key for Ethereum node
const infuraId = process.env.NEXT_PUBLIC_INFURA_ID;

// Chains for connectors to support
const chains = defaultChains;

const provider = (config: {
  chainId?: number | undefined;
  connector?: Connector<any, any> | undefined;
}): providers.BaseProvider => {
  try {
    return new providers.InfuraProvider(config.chainId, infuraId);
  } catch (error) {
    // If network does not exist, use default provider
    console.error("Error getting provider.", error);
    return new providers.InfuraProvider(undefined, infuraId);
  }
};

// Set up connectors
const connectors = ({ chainId }: { chainId?: number }) => {
  const rpcUrl =
    chains.find((x) => x.id === chainId)?.rpcUrls?.[0] ??
    chain.mainnet.rpcUrls[0];
  return [
    new InjectedConnector({ chains }),
    new WalletConnectConnector({
      options: {
        infuraId,
        qrcode: true,
      },
    }),
    new WalletLinkConnector({
      options: {
        appName: "Lobby",
        jsonRpcUrl: `${rpcUrl}/${infuraId}`,
      },
    }),
  ];
};

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    let _hjSettings = { hjid: 2800195, hjsv: 6 };
    hotjar.initialize(_hjSettings.hjid, _hjSettings.hjsv);
  }, []);

  // add dismiss action to all snackbars
  const notistackRef: RefObject<any> = createRef();
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <PreferencesProvider>
      <ApolloProvider client={client}>
        <Provider
          connectors={connectors}
          provider={provider}
          autoConnect={
            typeof window != "undefined" && !localStorage.getItem("token")
          }
          connectorStorageKey="lobby.wallet"
        >
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <div className="cursor-pointer" onClick={onClickDismiss(key)}>
                Dismiss
              </div>
            )}
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <AuthProvider>
              {/* <BaseLayout> */}
              <Component {...pageProps} />
              {/* </BaseLayout> */}
            </AuthProvider>
          </SnackbarProvider>
        </Provider>
      </ApolloProvider>
    </PreferencesProvider>
  );
}

export default MyApp;
