import { MentionOptions } from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";
import tippy, { Instance, Props } from "tippy.js";
import {
  QuerySearchArgs,
  SearchResponseObject,
} from "../../../../../generated/graphql";
import client from "../../../../../lib/apollo/apollo-client";
import { SearchQuery } from "../../../../../lib/queries/Search";
import { MentionList, MentionListRef } from "../MentionList/MentionList";

export const suggestion: MentionOptions["suggestion"] = {
  char: "[",
  allowSpaces: true,
  items: async ({ editor, query }) => {
    if (!editor.isEditable || query === "" || query === " ") return [];
    const communitySlug = editor.storage["communitySlug"];

    if (communitySlug === undefined) return [];

    try {
      const searchResult = await client.query<
        { search: SearchResponseObject },
        QuerySearchArgs
      >({
        query: SearchQuery,
        variables: {
          communitySlug,
          query: query,
        },
      });

      return searchResult.data.search.results;
    } catch (error) {
      console.log("Link search suggestion error.", error);
    }

    return [];
  },

  render: () => {
    let component: ReactRenderer<MentionListRef>;
    let popup: Instance<Props>[];

    return {
      onStart: (props: SuggestionProps) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props: SuggestionProps) {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props: SuggestionKeyDownProps) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        if (!component.ref) {
          return false;
        }

        return component.ref.onKeyDown(props);
      },

      onExit(_: SuggestionProps) {
        popup && popup[0].destroy();
        component && component.destroy();
      },
    };
  },
};
