import { distanceInWords } from "date-fns";
import { SiweMessage } from "siwe";
import sanitizeHtml from "sanitize-html";
import { getArticleStorageKey } from "./util.tiptap";
import { setContent } from "lobby-editor";
import { Editor } from "@tiptap/core";

/**
 * Capitalizes first letter of a string provided
 * @param string string to be captialized
 * @returns the string with a capitail
 */
function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Trims string if greater than length and adds ellipsis
 * @param string string to be captialized
 * @param length default 10
 * @returns the trimmed string
 */
export function trimEllip(string: string, length: number = 15) {
  return string.length > length
    ? string.substring(0, length - 3) + "..."
    : string;
}

/**
 * Custom encoding to uri
 * @param uri uri to be encoded
 * @returns an encoded to uri string
 */
export function customEncodeURIComponent(str: string): string {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^0-9a-z ]/gi, "")
    .split(" ")
    .join("-");
}

/**
 * Converts a slug to a title (new%20doc -> New Doc)
 * @param slug encouded uri slig
 * @returns the slug in title form
 */
export function slugToTitle(slug: string) {
  const parts = decodeURIComponent(slug).split(" ");
  return parts
    .map((part) => {
      return capitalizeFirstLetter(part);
    })
    .join(" ");
}

/**
 * Gets community slug from as path
 * @Note assumes on a page with community path
 * @param asPath the router as path
 * @returns the community slug
 */
export const parseCommunitySlug = (asPath: string): string => {
  return asPath.split("/")[1];
};

/**
 * Gets document slug from as path
 * @Note assumes on a page with community path
 * @param asPath the router as path
 * @returns the document slug
 */
export const parseDocumentSlug = (asPath: string): string => {
  const slugs = asPath.split("/");
  if (slugs.length < 3) return "";
  return slugs[2];
};

/**
 * Gets moderator name from as path
 * @Note assumes on a page with community path
 * @param asPath the router as path
 * @returns the moderator name/action
 */
export const parseModeratorName = (asPath: string): string => {
  const slugs = asPath.split("moderator");
  if (slugs.length !== 2 || !slugs[1]) return "Moderator";
  return capitalizeFirstLetter(slugs[1].replace("/", "").replace("-", " "));
};

/**
 * Gets admin name from as path
 * @Note assumes on a page with community path
 * @param asPath the router as path
 * @returns the admin name/action
 */
export const parseAdminName = (asPath: string): string => {
  const slugs = asPath.split("admin");
  if (slugs.length !== 2 || !slugs[1]) return "Admin";
  return capitalizeFirstLetter(slugs[1].replace("/", "").replace("-", " "));
};

/**
 * Create a sign in message for the user to sign
 * @param address address of user trying to auth
 * @param nonce random nonce from the user (should match api)
 * @param chainId
 * @returns a sign in message
 */
export const createSignInMessage = ({
  address,
  nonce,
  chainId,
}: {
  address: string;
  nonce: string;
  chainId: number;
}): string => {
  const statement =
    "Welcome to Lobby! Please sign this message in order to log in.";

  const message = new SiweMessage({
    domain: window.location.host,
    address,
    statement,
    nonce,
    uri: window.location.origin,
    version: "1",
    chainId,
  });

  return message.prepareMessage();
};

/**
 * Checks whether it's a client (browser) or ssr
 * @returns a boolean whether the is using a browser
 */
export const isClient = () =>
  !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );

/**
 * Gets a human readable string of time since the date provided
 * @param date in the past
 * @returns string with how long ago the date was
 */
export const timeSince = (date: Date) => {
  return distanceInWords(date, new Date());
};

/**
 * copies text to keyboard
 * @param text
 */
export async function copyText(text: string) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (e) {
    try {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    } catch (e) {
      console.error("Failed to copy text", e);
    }
  }
}
/**
 * adds img tag to the html so it can be sanitized
 * @param html
 */
export function sanitize(html: string) {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
  });
}
/**
 * get the content which is saved in session storage
 * @param locale current user locale
 * @param communitySlug current community slug
 * @param documentSlug current document slug
 */
export function getAutoSavedContent(
  locale: string | undefined,
  communitySlug: string,
  documentSlug: string
) {
  return (
    sessionStorage.getItem(
      getArticleStorageKey(locale ?? "en-US", communitySlug, documentSlug)
    ) ?? ""
  );
}
/**
 * Gets admin panel slug from as path
 * @Note assumes on a page with /[community]/admin path
 * @param asPath the router as path
 * @returns the current admin page user is on
 */

export function parseAdminPanelSlug(asPath: string) {
  const slugs = asPath.split("admin");
  if (slugs.length !== 2 || !slugs[1]) return "";
  return slugs[1].replace("/", "");
}
/**
 * Returns string matches the pattern supplied
 * @param string string
 * @param pattern regex pattern to match
 * @returns if the string matched the pattern
 */
export function patternMatch(string: string, pattern: string) {
  const regex = new RegExp(pattern);
  return regex.test(string);
}

/**
 * Returns the translation object from an array using given locale
 * @param translations Array of objects with language field
 * @param locale
 * @returns translation object
 */
export function findTranslation(translations: any[], locale: string) {
  return translations.find((translation) => translation.language === locale);
}
/**
 * Returns the data with updated url to our paid lobby pinata gateway
 * @param content
 * @returns data with updated url
 */
export function updateContentWithPinataURL(data: string) {
  return data.replaceAll(
    "https://cloudflare-ipfs.com/ipfs/",
    "https://lobby-kb.mypinata.cloud/ipfs/"
  );
}
/**
 * Sets the content of the editor with update pinata URL
 * @param editor
 * @param content
 */
export function setContentWithPinataURL({
  editor,
  content,
}: {
  editor?: Editor;
  content: string;
}) {
  setContent({
    editor,
    content: updateContentWithPinataURL(content),
  });
}
