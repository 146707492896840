import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  Reference,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const uri =
  process.env.NODE_ENV === "development"
    ? process.env.NEXT_PUBLIC_LOCAL_BACKEND_URL
    : process.env.NEXT_PUBLIC_BACKEND_URL;

export const httpLink = createHttpLink({
  uri: uri,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Document: {
        keyFields: ["documentId"],
        fields: {
          id: {
            read(id, { readField }) {
              return id || readField("documentId");
            },
          },
        },
      },
      Query: {
        fields: {
          community: {
            keyArgs: ["communityId"],
            merge: true,
          },
          documents: {
            keyArgs: ["communitySlug"],
            merge(
              existing: { documents: Reference[]; hasMore: boolean },
              incoming: { documents: Reference[]; hasMore: boolean },
              { readField }
            ) {
              if (!existing) return incoming;
              // array and map (since map doesn't store its keys)
              const ids = new Set<string>();
              const items = new Map<string, Reference>();

              existing.documents.forEach((item) => {
                const id = readField<string>("documentId", item);
                if (id) {
                  items.set(id, item);
                  ids.add(id);
                }
              });

              incoming.documents.forEach((item) => {
                const id = readField<string>("documentId", item);
                if (id) {
                  items.set(id, item);
                  ids.add(id);
                }
              });

              return {
                documents: Array.from(ids).map((id) => items.get(id)),
                hasMore: incoming.hasMore,
              };
            },
          },
        },
      },
    },
  }),
  connectToDevTools: process.env.NODE_ENV === "development",
});

export const setAuthToken = async (token: string) => {
  client.setLink(
    setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    }).concat(httpLink)
  );
  client.stop();
  await client.resetStore();
};

export default client;
